import "../../../Assets/Css/admin.css";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect } from "react";
import { getAllavtar } from "../../../Api/auth";
import { baseURL } from "../../../Api/api";

const Imageshow = (props) => {
  const [allavtar, setAllavtar] = useState([]);

  const submit = async () => {
    const response = await getAllavtar();
    if (response.remote === "success") {
      setAllavtar(
        response.data.allAvatar.map((contact, idx) => {
          return {
            _id: contact?.mediaId._id,
            id: idx + 1,
            photoURL: contact?.mediaId.path,
          };
        })
      );
    } else {
      // setErrors(response.errors.errors);
      console.log(response.errors);
      // setIsLoading(false);
    }
  };
  useEffect(() => {
    submit();
  }, []);

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">show avtar</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="container">
            <div className="Edit_profile_formpage">
              <div className="edit_profile_form_page ">
                <form>
                  <div className="row">
                    {allavtar.map((item) => (
                      <div className="mb-3 col-2">
                        <div
                          className="addavtarmodal_img"
                          onClick={() => (
                            props.setImgshow(item.photoURL), props.onHide()
                          )}
                        >
                          <img src={`${baseURL}${item.photoURL}`} alt="avtar" />
                        </div>
                      </div>
                    ))}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Form>
        {/* <div className="">
          <Button className="mb-5 ms-2" onClick={() => props.onHide()}>
            Cancel
          </Button>
          <Button
            className="mb-5 ms-3"
            // onClick={createavtart}
          >
            Save
          </Button>
        </div> */}
      </Modal.Body>
    </Modal>
  );
};
export default Imageshow;
