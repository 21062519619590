import { React, useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import "../../Assets/Css/admin.css";
import { Button } from "react-bootstrap";
import Addquote from "./Modal/Addquote";
import { deletequotes, getQuotes } from "../../Api/auth";
import moment from "moment";
import Swal from "sweetalert2";
import { Stack, Box } from "@mui/material";

const QuotesManagement = () => {
  const [modalShow, setModalShow] = useState(false);
  const [allquote, setAllquote] = useState([]);
  const [quoteid, setQuoteId] = useState("");
  const [searchvalue, setSearchValue] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const submit = async () => {
    const response = await getQuotes();
    if (response.remote == "success") {
      setSearchValue(
        response.data.quote.map((quote, idx) => {
          return {
            _id: quote._id,
            id: idx + 1,
            Date: moment(quote.createdAt).format("DD-MM-YYYY"),
            title: quote.title,
            quoteOwner: quote.quoteOwner,
            descriptionL: quote.description,
            status: quote.status,
          };
        })
      );
      setAllquote(
        response.data.quote.map((quote, idx) => {
          return {
            _id: quote._id,
            id: idx + 1,
            Date: moment(quote.createdAt).format("DD-MM-YYYY"),
            title: quote.title,
            quoteOwner: quote.quoteOwner,
            descriptionL: quote.description,
            status: quote.status,
          };
        })
      );
      setIsLoading(false);
    } else {
      // setErrors(response.errors.errors);
      console.log(response.errors);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    submit();
  }, []);
  // const onLogout = (e) => {
  //   const r = window.confirm("Do you really want to Log Out?");
  //   if (r === true) {
  //     dispatch(emitLogout());
  //     dispatch({ type: "RESET_DATE" });
  //     history.push("/login");
  //   }
  // };
  const deletequote = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deletequotes(id);
        if (response.remote == "success") {
          submit();
        } else {
          // setErrors(response.errors.errors);
          console.log(response.errors);
          // setIsLoading(false);
        }
        Swal.fire("Deleted!", "Your record  has been deleted.", "success");
      }
    });
  };

  const columns = [
    { field: "id", headerName: "  Id", width: 70 },
    { field: "Date", headerName: "Date", width: 110 },
    { field: "title", headerName: "Title", width: 150 },
    { field: "quoteOwner", headerName: "Quote Owner", width: 150 },
    { field: "descriptionL", headerName: "Description", width: 320 },
    {
      field: "status",
      headerName: "Status",
      width: 110,
      renderCell: (params) => {
        return (
          <div
            className={`cellWithStatus ${
              params.row.status ? "active" : "inactive"
            }`}
          >
            {params.row.status ? "Active" : "Inactive"}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 130,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              className="viewButton"
              onClick={() => (setQuoteId(params.row._id), setModalShow(true))}
            >
              Edit
            </div>

            <div
              className="deleteButton"
              onClick={() => deletequote(params.row._id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  const onSearch = (query) => {
    let filter = searchvalue.filter((item) =>
      Object.values(item).join("").toLowerCase().includes(query.toLowerCase())
    );
    setAllquote(filter);
  };
  return (
    <>
      <div className="">
        <div className="search_area">
          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            justifyContent={"flex-end"}
            sx={{ mb: 2 }}
          >
            <input
              type="text"
              class="form-control "
              id="Inputtext"
              placeholder="search area"
              onChange={(e) => {
                onSearch(e.target.value);
              }}
              style={{ width: "33%" }}
            />
            <Button>Search</Button>
            <Button onClick={() => setModalShow(true)}>Add New Quote</Button>
          </Stack>
        </div>
        <Box
          sx={{
            height: 475,
            width: "100%",
            "@media (min-width:1200px)": { height: 740 },
          }}
          className="loadercenter"
        >
          <DataGrid
            rows={allquote}
            columns={columns}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            loading={isLoading}
          />
        </Box>
      </div>
      <Addquote
        submit={submit}
        quoteid={quoteid}
        show={modalShow}
        onHide={() => (setModalShow(false), setQuoteId(""))}
      />
    </>
  );
};

export default QuotesManagement;
