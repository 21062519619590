import React, { useState, useEffect } from "react";
import "../../../Assets/Css/admin.css";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
// import { styled } from "@mui/material/styles";
// import { uploadIcon } from "../../../utils/svg.file";
// import Editicon from "../../../Assets/images/editicon.png";
// import Profile from "../../../Assets/images/placeholder.png";
import { createapi, getOnlyApi, updateApis } from "../../../Api/auth";
// import { fabClasses } from "@mui/material";
import Swal from "sweetalert2";

const Addnewapi = (props) => {
  const [date, setDate] = useState("");
  const [apiname, setApiname] = useState("");
  const [status, setStatus] = useState(false);
  const [apilink, setApilink] = useState("");
  // console.log(props.apiid, "api iidd");

  const createapis = async () => {
    let err = false;
    if (apiname === "" || apiname === undefined) {
      err = true;
      Swal.fire({
        icon: "error",
        title: "Please Enter Api Name ",
        text: "Required field",
      });
    }
    if (date === "" || date === undefined) {
      err = true;
      Swal.fire({
        icon: "error",
        title: "Please Enter Expire Date ",
        text: "Required field",
      });
    }
    if (apilink === "" || apilink === undefined) {
      err = true;
      Swal.fire({
        icon: "error",
        title: "Please Enter Api Link ",
        text: "Required field",
      });
    }

    if (!err) {
      let payload = {
        name: apiname,
        status: status,
        validTill: date,
        apiLink: apilink,
      };
      // console.log(payload, "api");
      const response = await createapi(payload);
      if (response.remote == "success") {
        props.getallapi();
        props.onHide();
      } else {
        console.log(response.errors);
      }
    }
  };

  const getapi = async (id) => {
    const response = await getOnlyApi(id);
    if (response.remote == "success") {
      // console.log(response.data.apiData, "get api");
      setApiname(response.data.apiData.name);
      setStatus(response.data.apiData.status);
      setApilink(response.data.apiData.apiLink);
      setDate(response.data.apiData.validTill);
      // setStatus(response.data.city.status);

      // setOnlycountry(response.data.country.countryId.name);
    } else {
      console.log(response.errors);
    }
  };
  useEffect(() => {
    getapi(props.apiid);
  }, [props.apiid]);

  const UpdateAPi = async () => {
    let payload = {
      name: apiname,
      status: status,
      validTill: date,
      apiLink: apilink,
      apiId: props.apiid,
    };
    // console.log(payload, "api");
    const response = await updateApis(payload);
    if (response.remote == "success") {
      props.getallapi();
      props.onHide();
    } else {
      console.log(response.errors);
    }
  };

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.apiid ? "Update Api " : "Add New Api"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="container">
            <div className="Edit_profile_formpage">
              <div className="edit_profile_form_page ">
                <form>
                  <div className="row">
                    <div className="mb-3 col-lg-6">
                      <label
                        for="exampleInputText"
                        class="form-label edit_profile_lebal"
                      >
                        API Name
                      </label>
                      <input
                        type="text"
                        class="form-control edit_profile_input_area"
                        id="exampleInputText"
                        aria-describedby="emailHelp"
                        placeholder="API Name"
                        value={apiname}
                        name="name"
                        onChange={(e) => {
                          setApiname(e.target.value);
                        }}
                        // value={profileusername}
                      />
                    </div>
                    <div className="mb-3 col-lg-6">
                      <label
                        for="exampleInputtext"
                        class="form-label edit_profile_lebal"
                      >
                        Status
                      </label>
                      <Form.Check
                        type="switch"
                        name="status"
                        id="custom-switch"
                        label="Active"
                        className="status_switch ms-3"
                        checked={status}
                        onChange={(e) => {
                          setStatus(e.target.checked);
                        }}
                      />
                    </div>

                    <div className="mb-3 col-lg-6">
                      <label
                        for="exampleInputText"
                        class="form-label edit_profile_lebal"
                      >
                        Valid Till
                      </label>
                      <input
                        type="date"
                        class="form-control edit_profile_input_area"
                        id="exampleInputText"
                        aria-describedby="emailHelp"
                        placeholder="yy-mm-dd"
                        value={date}
                        onChange={(e) => {
                          setDate(e.target.value);
                        }}
                        // value={profileusername}
                      />
                    </div>
                    <div className="mb-3 col-lg-6">
                      <label
                        for="exampleInputText"
                        class="form-label edit_profile_lebal"
                      >
                        API Link
                      </label>
                      <input
                        type="text"
                        class="form-control edit_profile_input_area"
                        id="exampleInputText"
                        aria-describedby="emailHelp"
                        placeholder="API Link"
                        name="link"
                        value={apilink}
                        onChange={(e) => {
                          setApilink(e.target.value);
                        }}
                        // value={profileusername}
                      />
                    </div>
                  </div>
                </form>
                <div className="text-center">
                  <Button
                    className="mb-5 "
                    onClick={() => (
                      props.onHide(),
                      setApiname(""),
                      setStatus(false),
                      setApilink(""),
                      setDate("")
                    )}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="mb-5 ms-2"
                    onClick={props.apiid !== "" ? UpdateAPi : createapis}
                  >
                    {props.apiid ? "Update" : " Add "}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Addnewapi;
