import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./Login";
import Admin from "../AdminPanel/Admin";
import { ManageUser } from "./Component/ManageUser";
import Avatar1 from "./Component/Avatar1";
import ReportedUserTable from "./Component/ReportedUserTable";
import Dashboard from "./Component/Dashboard";
import QuotesManagement from "./Component/QuotesManagement";
import ReceivedNotification from "./Component/ReceivedNotification";
import SendNotification from "./Component/SendNotification";
import AdminNotification from "./Component/AdminNotification";
import Api from "./Component/Api";
import TopicsManagement from "./Component/TopicsManagement";
import SubtopicManagment from "./Component/SubtopicManagment";
import Country from "./Component/Country";
import City from "./Component/City";
import ContentManagement from "./Component/ContentManagement";
import Profile from "./Profile";
import GroupManagement from "./Component/groupmanagement";
import ReportMgmt from "./Component/ReportMgmt";

const Layout = () => {
  return (
    <>
      <Admin>
        <Routes>
          <Route path="/manageuser" element={<ManageUser />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/reportedusertable" element={<ReportedUserTable />} />
          <Route path="/avatar1" element={<Avatar1 />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/quotesmanagement" element={<QuotesManagement />} />
          <Route
            path="/receivednotification"
            element={<ReceivedNotification />}
          />
          <Route path="/sendnotification" element={<SendNotification />} />
          <Route path="/adminnotification" element={<AdminNotification />} />
          <Route path="/api" element={<Api />} />
          <Route path="/topicsmanagement" element={<TopicsManagement />} />
          <Route path="/subtopicmanagment" element={<SubtopicManagment />} />
          <Route path="/country" element={<Country />} />
          <Route path="/city" element={<City />} />
          <Route path="/contentmanagement" element={<ContentManagement />} />
          <Route path="/groupmanagement" element={<GroupManagement />} />
          <Route path="/reportmgmt" element={<ReportMgmt />} />
        </Routes>
      </Admin>
    </>
  );
};

export default Layout;
