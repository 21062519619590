import { React, useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import "../../Assets/Css/admin.css";
import { Button } from "react-bootstrap";
import Sendnoti from "./Modal/Sendnoti";
import moment from "moment";
import { getAllNotification } from "../../Api/auth";
import { Stack, Box } from "@mui/material";

const SendNotification = () => {
  const [modalShow, setModalShow] = useState(false);
  const [searchvalue, setSearchValue] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const columns = [
    { field: "id", headerName: "Id", width: 70 },
    {
      field: "Date",
      headerName: "Date",
      width: 130,
      valueFormatter: ({ value }) => {
        return value;
      },
    },
    {
      field: "message",
      headerName: " Message",
      width: 440,
    },

    {
      field: "notificationSenderId",
      headerName: "Sender Initial",
      width: 130,
      valueFormatter: ({ value }) => {
        return value ? value : "Admin";
      },
    },
    {
      field: "notifiationReceiverId",
      headerName: "Receiver Initial",
      width: 130,
      valueFormatter: ({ value }) => {
        return value ? value : "All";
      },
    },
  ];

  useEffect(() => {
    getnotificationData();
  }, []);
  const getnotificationData = async () => {
    const data = await getAllNotification();
    let response = [];
    data.data.data.map((el) => el.isAdmin != true && response.push(el));

    if (data.remote == "success") {
      setSearchValue(
        response.map((notification, idx) => {
          console.log(notification, "nooo");
          return {
            _id: notification._id,
            id: idx + 1,
            Date: moment(notification.createdAt).format("DD-MM-YYYY"),
            message: notification.message,
            notificationSenderId:
              notification?.notificationSenderId?.firstInitial +
              notification?.notificationSenderId?.lastInitial,
            notifiationReceiverId:
              notification?.notifiationReceiverId?.firstInitial +
              notification?.notifiationReceiverId?.lastInitial,
          };
        })
      );
      setNotificationData(
        response.map((notification, idx) => {
          return {
            _id: notification._id,
            id: idx + 1,
            Date: moment(notification.createdAt).format("DD-MM-YYYY"),
            message: notification.message,
            notificationSenderId:
              notification?.notificationSenderId?.firstInitial +
              notification?.notificationSenderId?.lastInitial,
            notifiationReceiverId:
              notification?.notifiationReceiverId?.firstInitial +
              notification?.notifiationReceiverId?.lastInitial,
          };
        })
      );
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const onSearch = (query) => {
    let filter = searchvalue.filter((item) =>
      Object.values(item).join("").toLowerCase().includes(query.toLowerCase())
    );
    setNotificationData(filter);
  };
  return (
    <>
      <div className="">
        <div className="search_area">
          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            justifyContent={"flex-end"}
            sx={{ mb: 2 }}
          >
            <input
              type="text"
              class="form-control "
              id="Inputtext"
              placeholder="search area"
              onChange={(e) => {
                onSearch(e.target.value);
              }}
              style={{ width: "33%" }}
            />
            <Button>Search</Button>
          </Stack>
        </div>
        <Box
          sx={{
            height: 475,
            width: "100%",
            "@media (min-width:1200px)": { height: 740 },
          }}
          className="loadercenter"
        >
          <DataGrid
            getRowId={(row) => row._id}
            rows={notificationData}
            columns={columns}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            loading={isLoading}
          />
        </Box>
      </div>
      <Sendnoti show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default SendNotification;
