import axios from "axios";
import { tokens } from "./jwtTokenStroge";

export const baseURL = "https://api.gohuddle.live/";
// export const baseURL = "http://192.168.0.154:3005/";

export const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});
axiosInstance.interceptors.response.use(function (response) {
  const newToken = response.data.token;

  if (newToken && tokens.token() !== newToken) {
    tokens.setToken(newToken);
  }
  return response;
});

const parseResponse = (response) => {
  try {
    const data = JSON.parse(response);

    if (data?.errors) {
      return {
        remote: "failure",
        error: {
          errors: data.errors,
        },
      };
    }
    return {
      remote: "success",
      data: data,
    };
  } catch (error) {
    return {
      remote: "failure",
      error: {
        errors: response,
      },
    };
  }
};

const request = async (config) => {
  try {
    if (!config.headers) {
      config.headers = {};
    }

    if (!config.headers["Content-Type"]) {
      config.headers["Content-Type"] = "application/json";
    }

    config.headers["Authorization"] = `Bearer ${tokens.token()}`;

    const response = await axiosInstance.request({ ...config });
    return {
      remote: "success",
      data: response.data,
    };
  } catch (error) {
    console.log({ error });
    if (error) {
      if (error?.response) {
        const axiosError = error;
        if (axiosError?.response?.data) {
          let errorMessage =
            axiosError?.response?.data?.message ||
            axiosError?.response?.data?.errors;

          return {
            remote: "failure",
            errors: {
              status: axiosError?.response?.status,
              errors: errorMessage,
            },
          };
        } else {
          const axiosError = error;
          let errorMessage = axiosError.message;
          if (errorMessage === "Network Error") {
            errorMessage = "No internet connection";
          }
          return {
            remote: "failure",
            errors: {
              errors: errorMessage,
            },
          };
        }
      }
      return error;
    }
  }
};

const toExport = {
  request,
  parseResponse,
  axiosInstance,
};
export default toExport;
