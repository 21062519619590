import React, { Fragment } from "react";
import "../Assets/Css/admin.css";
import { NavLink, useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { Dropdown, Form, Stack } from "react-bootstrap";
import { useState } from "react";
import { AiOutlineDown } from "react-icons/ai";
import Notifications from "../AdminPanel/Notifications";
import { CiSearch } from "react-icons/ci";
import Avatar1 from "../Assets/images/avatar-1.jpg";
import { FiBell } from "react-icons/fi";
import { AiOutlineUser } from "react-icons/ai";

const Admin = ({ children }) => {
  let navigate = useNavigate();

  const [onClick, setOnClick] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const closeButton = () => setIsOpen(false);

  const closeSubmenu = (key) => setOnClick(key === onClick ? null : key);
  const menuItem = [
    {
      path: "/dashboard",
      name: "Dashboard",
    },
    {
      path: "/manageuser",
      name: "User  Management",
    },
    {
      path: "/quotesmanagement",
      name: "Quotes  Management",
    },
    {
      path: "/receivednotification",
      name: "Report Users",
    },
    {
      path: "/sendnotification",
      name: "All Notification",
    },
    {
      path: "/adminnotification",
      name: "Admin Notification",
    },
    {
      path: "/groupmanagement",
      name: "Group Management",
    },
    {
      path: "/topicsmanagement",
      name: "Topics Management",
    },
    {
      path: "/subtopicmanagment",
      name: "Subtopic Managment",
    },
    {
      path: "/country",
      name: "Country",
    },
    {
      path: "/city",
      name: "State",
    },
    {
      path: "/contentmanagement",
      name: "User Contact",
    },

    {
      path: "/avatar1",
      name: "Avatar",
    },
    {
      path: "/reportmgmt",
      name: "Report Management",
    },
  ];
  return (
    <>
      <div className="header">
        <div
          className="bars"
          style={{ marginLeft: isOpen ? "10px" : "10px", marginRight: "10px" }}
        >
          <FaBars onClick={toggle} className="d-lg-none d-md-block d-block" />
        </div>
        <div className="ms-auto d-lg-block d-md-block d-none">
          <div className="d-flex">
            {/* <Form className="d-flex">
              <div className="header_search_input d-lg-block d-md-block d-none">
                <Form.Control
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  className="header_search_form_input"
                />
                <CiSearch className="header_search_icon" />
              </div>
            </Form> */}
            <Dropdown className="mx-3 mt-2">
              <Dropdown.Toggle as="a" className="dropdown" id="dropdown-basic">
                <FiBell aria-hidden="true" className="text-light" />
                {/* <span className="badge rounded-pill">3</span> */}
              </Dropdown.Toggle>

              <Dropdown.Menu className="drop_menu_notification mt-4">
                <Dropdown.Item href="#/action-1">
                  <Notifications />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown className="ms-3">
              <Dropdown.Toggle
                variant="outline"
                id="dropdown-basic"
                className="header_sidebar_dropdown text-light"
              >
                <img src={Avatar1} alt="" className="Avatar20" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="mt-2">
                <Dropdown.Item onClick={() => navigate("/profile")}>
                  Profile
                </Dropdown.Item>
                <hr className="m-0" />
                {/* <Dropdown.Item href="#/action-2">Forgot Password</Dropdown.Item> */}
                <hr className="m-0" />
                <Dropdown.Item
                  onClick={() => {
                    localStorage.clear();
                    navigate("/");
                  }}
                >
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>

      <div className="combind">
        <div className={`sidebar ${isOpen ? "mobileview" : "destopview"}`}>
          <div className="sidebar_img_icon">
            <h1 className="sidebar_head">Huddle</h1>
            <AiOutlineClose
              className="sidebar_close_button"
              onClick={closeButton}
            />
          </div>
          <div className="slider_admin">
            {menuItem.map((item, index) => (
              <Fragment key={index}>
                <NavLink to={item.path} key={index} className={`link my-3`}>
                  <div
                    className="link_text "
                    onClick={() => closeSubmenu(item.name)}
                  >
                    <div className="sidebar_category ">{item.name}</div>
                  </div>
                </NavLink>
              </Fragment>
            ))}
          </div>
        </div>

        <div className="contain">
          <div className="p-3">{children}</div>
        </div>
      </div>
    </>
  );
};

export default Admin;
