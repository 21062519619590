import "../../../Assets/Css/admin.css";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { createContactus } from "../../../Api/auth";
import Swal from "sweetalert2";
// import { styled } from "@mui/material/styles";
// import { uploadIcon } from "../../../utils/svg.file";
// import Editicon from "../../../Assets/images/editicon.png";
// import Profile from "../../../Assets/images/placeholder.png";
const Addpage = (props) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const createContact = async () => {
    let err = false;
    if (title === "" || title === undefined) {
      err = true;
      Swal.fire({
        icon: "error",
        title: "Please Enter Title",
        text: "Required field",
      });
    }
    if (description === "" || description === undefined) {
      err = true;
      Swal.fire({
        icon: "error",
        title: "Please Enter description",
        text: "Required field",
      });
    }
    if (!err) {
      let payload = {
        title: title,
        description: description,
      };

      const response = await createContactus(payload);
      if (response.remote == "success") {
        props.submit();
        props.onHide();
      } else {
        props.onHide();
      }
    }
  };

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Contact
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="container">
            <div className="Edit_profile_formpage">
              <div className="edit_profile_form_page ">
                <form>
                  <div className="row">
                    <div className="mb-3 col-lg-6">
                      <label
                        for="exampleInputText"
                        class="form-label edit_profile_lebal"
                      >
                        TITLE
                      </label>
                      <input
                        type="text"
                        class="form-control edit_profile_input_area"
                        id="exampleInputText"
                        aria-describedby="emailHelp"
                        placeholder="Enter Title"
                        // value={profileusername}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                    <div class="mb-3">
                      <label
                        for="exampleFormControlTextarea1"
                        className="form-label edit_profile_lebal"
                      >
                        Description
                      </label>
                      <textarea
                        className="form-control edit_profile_input_area"
                        id="exampleFormControlTextarea1"
                        placeholder="Enter description"
                        rows="3"
                        // defaultValue={profilebio}
                        onChange={(e) => setDescription(e.target.value)}
                      ></textarea>
                    </div>

                    {/* <div className="mb-3 col-lg-6">
                      <label
                        for="exampleInputtext"
                        class="form-label edit_profile_lebal"
                      >
                        Status
                      </label>
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Active"
                        className="status_switch ms-3"
                      />
                    </div> */}
                  </div>
                </form>
                <div className="text-center">
                  <Button className="mb-5 " onClick={() => props.onHide()}>
                    Cancel
                  </Button>
                  <Button className="mb-5 ms-3" onClick={createContact}>
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Addpage;
