import React, { useState, useEffect } from "react";
import "../../../Assets/Css/admin.css";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import { uploadIcon } from "../../../utils/svg.file";
import Editicon from "../../../Assets/images/editicon.png";
import Profile from "../../../Assets/images/placeholder.png";
import {
  showCities,
  getAllCountry,
  createCity,
  getcountrys,
} from "../../../Api/auth";
const Addcity = (props) => {
  const [allcountry, setAllcountry] = React.useState([]);
  const [countryname, setCountryname] = React.useState("");
  const [cityname, setCityname] = React.useState([]);
  const [status, setStatus] = React.useState(false);
  const [stateDetail, setStateDetail] = useState({ name: "", code: "" });

  const [selectCountry, setSelectCountry] = useState({
    countryId: "",
    coutryIsoId: "",
    countryName: "",
    id: "",
  });

  const submit = async () => {
    const response = await getcountrys();
    if (response.remote == "success") {
      setAllcountry(response.data.country);
      setSelectCountry({
        countryId: response.data.country[0]?._id,
        coutryIsoId: response.data.country[0]?.iso2,
        countryName: response.data.country[0]?.name,
        id: response.data.country?.id,
      });
    } else {
      console.log(response.errors);
    }
  };

  useEffect(() => {
    submit();
  }, []);

  const getAllCity = async (cityName) => {
    const response = await showCities(cityName);
    if (response.remote == "success") {
      setCityname(response.data.city);
    } else {
      console.log(response.errors);
    }
  };

  useEffect(() => {
    if (selectCountry) {
      getAllCity(selectCountry.coutryIsoId);
    }
  }, [selectCountry]);

  const createCitys = async () => {
    let payload = {
      stateName: stateDetail.name,
      stateCode: stateDetail.code,
      countryId: selectCountry.countryId,
      countryName: selectCountry.countryName,
      id: selectCountry.id,
      isoId: selectCountry.coutryIsoId,
    };

    const response = await createCity(payload);
    if (response.remote == "success") {
      props.getallcitys();
      props.onHide();
    } else {
      console.log(response.errors);
    }
  };

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add State</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="container">
            <div className="Edit_profile_formpage">
              <div className="edit_profile_form_page ">
                <form>
                  <div className="row">
                    <div class="mb-3 col-lg-6">
                      <label for="exampleInputText" class="form-label ">
                        Country
                      </label>
                      {/* <label for="cars">Choose a car:</label> */}
                      <select
                        name="Country"
                        id="country"
                        className="form-control edit_profile_input_area"
                        onChange={(e) => {
                          // console.log("value --- ", e.target.value);
                          setSelectCountry((prev) => ({
                            ...prev,
                            countryId: JSON.parse(e.target.value).cId,
                            coutryIsoId: JSON.parse(e.target.value).isoId,
                            countryName: JSON.parse(e.target.value).name,
                            id: JSON.parse(e.target.value).countryId,
                          }));
                        }}
                      >
                        {allcountry?.map((item) => {
                          return (
                            <option
                              value={JSON.stringify({
                                isoId: item.iso2,
                                cId: item._id,
                                name: item.name,
                                countryId: item.id,
                              })}
                            >
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="mb-3 col-lg-6">
                      <label for="exampleInputtext" className="form-label ">
                        State
                      </label>

                      <input
                        type="text"
                        class="form-control edit_profile_input_area"
                        id="exampleInputText1"
                        aria-describedby="emailHelp"
                        placeholder="Type state name"
                        name="state name"
                        value={stateDetail.name}
                        onChange={(e) => {
                          setStateDetail({
                            ...stateDetail,
                            name: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div class="mb-3 col-lg-6">
                      <label for="exampleInputtext" className="form-label">
                        State Code
                      </label>
                      <input
                        type="text"
                        class="form-control edit_profile_input_area"
                        id="exampleInputText1"
                        aria-describedby="emailHelp"
                        placeholder="Type state code"
                        name="state code"
                        value={stateDetail.code}
                        onChange={(e) => {
                          setStateDetail({
                            ...stateDetail,
                            code: e.target.value,
                          });
                        }}
                      />
                    </div>

                    <div className="mb-3 col-lg-6 mt-4 d-flex align-items-center">
                      <label
                        for="exampleInputtext"
                        class="form-label edit_profile_lebal"
                      >
                        Status
                      </label>
                      <Form.Check
                        type="switch"
                        name="status"
                        id="custom-switch"
                        label="Active"
                        className="status_switch ms-2"
                        onChange={(e) => setStatus(e.target.checked)}
                      />
                    </div>
                  </div>
                </form>
                <div className="text-center">
                  <Button className="mb-5 " onClick={() => props.onHide()}>
                    Cancle
                  </Button>
                  <Button
                    className="mb-5 ms-3"
                    onClick={
                      stateDetail.name != "" &&
                      stateDetail.code != "" &&
                      createCitys
                    }
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Addcity;
