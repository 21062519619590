import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import "../../Assets/Css/admin.css";
import { Button } from "react-bootstrap";
import { getAllReportMgmt } from "../../Api/auth";
import { Stack, Box } from "@mui/material";

const ReportMgmt = () => {
  const [fullReport, setFullReport] = useState([]);
  const [searchvalue, setSearchValue] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const columns = [
    { field: "id", headerName: " ID", width: 70 },
    { field: "topic", headerName: "Topic", width: 200 },
    { field: "subTopic", headerName: "Sub Topic", width: 200 },
    { field: "groupFormated", headerName: "No. of Group Formed", width: 300 },
    {
      field: "userInteracted",
      headerName: "No. of User Interacted",
      width: 300,
    },
  ];

  useEffect(() => {
    allReport();
  }, []);

  const allReport = async () => {
    const response = await getAllReportMgmt();

    if (response.remote == "success") {
      setSearchValue(
        response.data.reports.map((report, idx) => {
          return {
            _id: report?._id?.subTopicId,
            id: idx + 1,
            topic: report?._id.topicId,
            subTopic: report?._id.subTopicName,
            userInteracted: report?.user?.length,
            groupFormated: report?.group?.length,
          };
        })
      );
      setFullReport(
        response.data.reports.map((report, idx) => {
          return {
            _id: report?._id,
            id: idx + 1,
            topic: report?.topic?.name,
            subTopic: report?.subTopicName,
            userInteracted: report?.user?.length,
            groupFormated: report?.group?.length,
          };
        })
      );
      setIsLoading(false);
    } else {
      // setErrors(response.errors.errors);
      console.log(response.errors);
      setIsLoading(false);
    }
  };

  const rows = [
    {
      id: 1,
      topic: "Feedback",
      subTopic: "check ",
      groupFormated: "Beautiful Day",
      userInteracted: "Beautiful Day",
      // Action: "Edit, delete, active/inactive",
    },
  ];

  return (
    <>
      <div className="">
        <div className="search_area">
          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            justifyContent={"flex-end"}
            sx={{ mb: 2 }}
          >
            <input
              type="text"
              class="form-control "
              id="Inputtext"
              placeholder="search area"
              onChange={(e) => {
                // onSearch(e.target.value);
              }}
              style={{ width: "33%" }}
            />
            <Button>Search</Button>
          </Stack>
        </div>
        <Box
          sx={{
            height: 475,
            width: "100%",
            "@media (min-width:1200px)": { height: 740 },
          }}
          className="loadercenter"
        >
          <DataGrid
            rows={fullReport}
            columns={columns}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            loading={isLoading}
          />
        </Box>
      </div>
    </>
  );
};

export default ReportMgmt;
