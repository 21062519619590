import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import "../../Assets/Css/admin.css";
// import avatr from "../../Assets/images/avatar-1.jpg";
import { Avatar as Profileimg, Stack, Box } from "@mui/material";
import { Button } from "react-bootstrap";
import AddAvtar from "./Modal/AddAvtar";
import { getAllavtar, deleteAvatar } from "../../Api/auth";
import moment from "moment";
import Swal from "sweetalert2";
import { baseURL } from "../../Api/api";

const Avatar1 = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [searchvalue, setSearchValue] = useState([]);
  const [allavtar, setAllavtar] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1); // Current page number
  const [pageSize, setPageSize] = useState(20);
  const [totalRows, setTotalRows] = useState(0); // Total number of rows
  const [rows, setRows] = useState([]); // Current page rows

  const submit = async () => {
    const response = await getAllavtar(1);
    console.log(response, "rs");
    if (response.remote == "success") {
      setSearchValue(
        response.data.allAvatar.map((contact, idx) => {
          return {
            _id: contact?._id,
            id: idx + 1,
            date: moment(contact?.mediaId.createdAt).format("DD-MM-YYYY"),
            photoURL: contact?.mediaId.path,
          };
        })
      );
      setAllavtar((prevAllavtar) => {
        // Add new data to the existing state
        setTotalRows(response?.data?.totalCount);
        const newData = response.data.allAvatar.map((contact, idx) => {
          return {
            _id: contact?._id,
            id: idx + 1, // Generate a new unique ID based on the current length
            date: moment(contact?.mediaId.createdAt).format("DD-MM-YYYY"),
            photoURL: contact?.mediaId,
          };
        });

        // Concatenate the new data with the existing state
        return newData;
      });
      setIsLoading(false);
    } else {
      // setErrors(response.errors.errors);
      console.log(response.errors);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    // if (totalRows > page * 20 || totalRows == 0) {
    submit();
    // }
  }, []);

  // Handle page change event

  const deleteAvatars = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteAvatar(id);
        if (response.remote == "success") {
          const newData = allavtar.filter((item) => {
            return item._id !== id;
          });

          setAllavtar(newData);
          // submit();
        } else {
          // setErrors(response.errors.errors);
          console.log(response.errors);
          // setIsLoading(false);
        }
        Swal.fire("Deleted!", "Your record  has been deleted.", "success");
      }
    });
  };

  const columns = [
    { field: "id", headerName: " Id", width: 70 },
    { field: "date", headerName: "Date", width: 130 },

    {
      field: "photoURL",
      headerName: "Avtar",
      width: 130,
      renderCell: (params) => {
        return (
          <Profileimg src={`${baseURL}/admin/avatars/${params.row.photoURL}`} />
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 300,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              className="deleteButton"
              onClick={() => deleteAvatars(params.row._id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="">
        <div className="search_area">
          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            justifyContent={"flex-end"}
            sx={{ mb: 2 }}
          >
            <input
              type="text"
              class="form-control "
              id="Inputtext"
              placeholder="search area"
              style={{ width: "33%" }}
            />
            <Button>Search</Button>
            <Button onClick={() => setModalShow(true)}>Add Avtar</Button>
          </Stack>
        </div>
        <Box
          sx={{
            height: 475,
            width: "100%",
            "@media (min-width:1200px)": { height: 740 },
          }}
          className="loadercenter"
        >
          <DataGrid
            rows={allavtar}
            columns={columns}
            initialState={{
              pagination: { paginationModel: { pageSize: 20 } },
            }}
            // pageSizeOptions={[10, 25, 50, 100]}
            loading={isLoading}
            // pagination
            // pageSize={pageSize}
            rowCount={totalRows}
            // onPaginationModelChange={(value) => handlePageChange(value)}
            // onPageChange={handlePageChange}
          />
        </Box>
      </div>
      <AddAvtar
        show={modalShow}
        onHide={() => setModalShow(false)}
        submit={submit}
      />
    </>
  );
};

export default Avatar1;
