import React from "react";
import "../../Assets/Css/preloader.css";

const PreLoader = () => {
  return (
    <div className="preLoaderContainer">
      <div className="loading-bar">
        <div className="progress-bar"></div>
      </div>
    </div>
  );
};

export default PreLoader;
