import { React, useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import "../../Assets/Css/admin.css";
import { Button } from "react-bootstrap";
import Addnewapi from "./Modal/Addnewapi";
import { getAllapi, deleteapi } from "../../Api/auth";
import moment from "moment";
import Swal from "sweetalert2";
import { Stack } from "@mui/material";

const Api = () => {
  const [modalShow, setModalShow] = useState(false);
  const [allapi, setAllapi] = useState([]);
  const [apiid, setApiId] = useState("");
  const [searchvalue, setSearchValue] = useState([]);

  const getallapi = async () => {
    const response = await getAllapi();
    if (response.remote == "success") {
      setSearchValue(
        response.data.apiData.map((api, idx) => {
          return {
            _id: api._id,
            id: idx + 1,
            APIName: api.name,
            Date: moment(api.createdAt).format("DD-MM-YYYY"),
            status: api?.status,
            ValidTill: api.validTill,
            APILink: api.apiLink,
          };
        })
      );
      setAllapi(
        response.data.apiData.map((api, idx) => {
          return {
            _id: api._id,
            id: idx + 1,
            APIName: api.name,
            Date: moment(api.createdAt).format("DD-MM-YYYY"),
            status: api?.status,
            ValidTill: api.validTill,
            APILink: api.apiLink,
          };
        })
      );
    } else {
      // setErrors(response.errors.errors);
      // setIsLoading(false);
    }
  };
  useEffect(() => {
    getallapi();
  }, []);

  const deleteApi = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteapi(id);
        if (response.remote == "success") {
          getallapi();
        } else {
          // setErrors(response.errors.errors);
          console.log(response.errors);
          // setIsLoading(false);
        }
        Swal.fire("Deleted!", "Your record  has been deleted.", "success");
      }
    });
  };

  const columns = [
    { field: "id", headerName: " ID", width: 70 },
    { field: "APIName", headerName: "API NAME", width: 170 },
    { field: "Date", headerName: "DATE", width: 110 },
    {
      field: "status",
      headerName: "STATUS",
      width: 110,
      renderCell: (params) => {
        return (
          <div
            className={`cellWithStatus ${
              params.row.status ? "active" : "inactive"
            }`}
          >
            {params.row.status ? "Active" : "Inactive"}
          </div>
        );
      },
    },
    { field: "ValidTill", headerName: "VALID TILL", width: 110 },
    { field: "APILink", headerName: "API LINK", width: 200 },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            {/* <Link to="/users/test" style={{ textDecoration: "none" }}> */}
            <div
              className="viewButton"
              onClick={() => (setApiId(params.row._id), setModalShow(true))}
            >
              Edit
            </div>
            {/* </Link> */}
            <div
              className="deleteButton"
              onClick={() => deleteApi(params.row._id)}
            >
              Delete
            </div>
            {/* <div
              className="activeButton"
              // onClick={() => handleDelete(params.row.id)}
            >
              Active
            </div>
            <div
              className="inactiveButton"
              // onClick={() => handleDelete(params.row.id)}
            >
              Inactive
            </div> */}
          </div>
        );
      },
    },
  ];

  const onSearch = (query) => {
    let filter = searchvalue.filter((item) =>
      Object.values(item).join("").toLowerCase().includes(query.toLowerCase())
    );
    setAllapi(filter);
  };
  return (
    <>
      <div className="">
        <div className="search_area">
          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            justifyContent={"flex-end"}
            sx={{ mb: 2 }}
          >
            <input
              type="text"
              class="form-control "
              id="Inputtext"
              onChange={(e) => {
                onSearch(e.target.value);
              }}
              placeholder="search area"
              style={{ width: "33%" }}
            />
            <Button onClick={() => setModalShow(true)}>Add New Api</Button>
          </Stack>
        </div>
        <div style={{ height: 600, width: "100%" }}>
          <DataGrid
            rows={allapi}
            columns={columns}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            // checkboxSelection
          />
        </div>
      </div>
      <Addnewapi
        getallapi={getallapi}
        apiid={apiid}
        show={modalShow}
        onHide={() => (setApiId(""), setModalShow(false))}
      />
    </>
  );
};

export default Api;
