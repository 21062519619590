import React, { useState, useEffect } from "react";
import "../../Assets/Css/DashboardCard.css";
import { FaCubes, FaDollarSign } from "react-icons/fa";
import {
  Woman,
  Man,
  Groups,
  Wc,
  PersonOff,
  Person,
  Topic,
} from "@mui/icons-material";
import { BiDiamond } from "react-icons/bi";
import { Col, Row } from "react-bootstrap";
import { getDashboard } from "../../Api/auth";

const DashboardCard = ({ icon, number, p }) => {
  const [allLength, setAllLength] = useState({
    topic: 0,
    inactiveUser: 0,
    activeUser: 0,
    xenderUser: 0,
    femaleUser: 0,
    maleUser: 0,
    subTopic: 0,
    groupLength: 0,
  });

  const submit = async () => {
    const response = await getDashboard();
    if (response.remote == "success") {
      setAllLength({
        topic: response.data.dashBoardData.allLength.topicLength,
        inactiveUser: response.data.dashBoardData.allLength.inActiveUser,
        activeUser: response.data.dashBoardData.allLength.activeUser,
        xenderUser: response.data.dashBoardData.allLength.xgender,
        femaleUser: response.data.dashBoardData.allLength.femaleLength,
        maleUser: response.data.dashBoardData.allLength.maleLength,
        subTopic: response.data.dashBoardData.allLength.subTopicLength,
        groupLength: response.data.dashBoardData.currentGroupLength,
      });
    } else {
      console.log(response.errors, "dashboard error");
    }
  };
  useEffect(() => {
    submit();
  }, []);

  const card = [
    {
      icon: <Groups fontSize={"1"} />,
      title: allLength.femaleUser + allLength.xenderUser + allLength.maleUser,
      des: "Total Users",
    },
    {
      icon: <Man fontSize={"1"} />,
      title: allLength.maleUser,
      des: "Male",
    },
    {
      icon: <Woman fontSize={"1"} />,
      title: allLength.femaleUser,
      des: "Female",
    },
    {
      icon: <Wc fontSize={"1"} />,
      title: allLength.xenderUser,
      des: "Xgender",
    },
    {
      icon: <Person fontSize={"1"} />,
      title: allLength.activeUser,
      des: "Active",
    },
    {
      icon: <PersonOff fontSize={"1"} />,
      title: allLength.inactiveUser,
      des: "Inactive",
    },
    {
      icon: <Topic fontSize={"1"} />,
      title: allLength.topic,
      des: "Topics",
    },
    {
      icon: <Topic fontSize={"1"} />,
      title: allLength.subTopic,
      des: "Sub topics",
    },
    {
      icon: <Groups fontSize={"1"} />,
      title: allLength.groupLength,
      des: "Groups",
    },
  ];

  return (
    <>
      <div className="row ">
        {card.map((itmes, index) => (
          <div className="col-md-3">
            <div className=" card1 my-2" key={index}>
              <div className="card_left">
                <span className="card-span">
                  <span className="cubes_icon"> {itmes.icon} </span>
                </span>
              </div>
              <div className="card_right">
                <strong>{itmes.title}</strong>
                <p>{itmes.des}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default DashboardCard;
