import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import "../../Assets/Css/admin.css";
import { Button } from "react-bootstrap";
import { getAllReport } from "../../Api/auth";
import moment from "moment";
import { Stack, Box } from "@mui/material";
import AddNewModal from "./Modal/AddNewModal";

const ReceivedNotification = () => {
  const [modalShow, setModalShow] = useState(false);
  const [searchvalue, setSearchValue] = useState([]);
  const [allreport, setAllreport] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userid, setUserId] = useState("");

  const submit = async () => {
    const response = await getAllReport();

    if (response.remote == "success") {
      setSearchValue(
        response.data.reportData.map((report, idx) => {
          return {
            _id: report?._id,
            id: idx + 1,
            date: moment(report?.createdAt).format("DD-MM-YYYY"),
            title: report?.reportType,
            reportby: report.reportByUserId
              ? report.reportByUserId?.firstInitial +
                report.reportByUserId?.lastInitial
              : "User Deleted",
            reportto: report?.reportToUserId
              ? report.reportToUserId?.firstInitial +
                report.reportToUserId?.lastInitial
              : "User Deleted",
            byDeviceId: report?.reportByUserId?.deviceId,
            toDeviceId: report?.reportToUserId?.deviceId,
            topic: report?.topicId?.name,
            subTopic: report?.subTopic,
          };
        })
      );
      setAllreport(
        response.data.reportData.map((report, idx) => {
          return {
            _id: report?._id,
            id: idx + 1,
            date: moment(report?.createdAt).format("DD-MM-YYYY"),
            title: report?.reportType,
            reportby: report.reportByUserId
              ? report.reportByUserId?.firstInitial +
                report.reportByUserId?.lastInitial
              : "User Deleted",
            reportto: report?.reportToUserId
              ? report.reportToUserId?.firstInitial +
                report.reportToUserId?.lastInitial
              : "User Deleted",
            byDeviceId: report?.reportByUserId?.deviceId,
            toDeviceId: report?.reportToUserId?.deviceId,
            topic: report?.topicId?.name,
            subTopic: report?.subTopic,
          };
        })
      );
      setIsLoading(false);
    } else {
      // setErrors(response.errors.errors);
      console.log(response.errors);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    submit();
  }, []);

  const columns = [
    { field: "id", headerName: " Id", width: 70 },
    { field: "date", headerName: "Date", width: 130 },
    { field: "title", headerName: "Report Type", width: 130 },
    {
      field: "reportby",
      headerName: "Report By",
      width: 130,
      renderCell: (params) => {
        return (
          <div
            onClick={() => {
              selectUser(params.row.byDeviceId);
            }}
          >
            {params.row.reportby}
          </div>
        );
      },
    },
    {
      field: "reportto",
      headerName: "Report To",
      width: 130,
      renderCell: (params) => {
        return (
          <div
            onClick={() => {
              selectUser(params.row.toDeviceId);
            }}
          >
            {params.row.reportto}
          </div>
        );
      },
    },
    { field: "topic", headerName: "Topic name", width: 130 },
    { field: "subTopic", headerName: "Sub Topic Name", width: 130 },

    // { field: "Notification", headerName: "NOTIFICATION", width: 230 },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   width: 300,
    //   renderCell: (params) => {
    //     return (
    //       <div className="cellAction">
    //         {/* <Link to="/users/test" style={{ textDecoration: "none" }}> */}
    //         <div className="viewButton">Edit</div>
    //         {/* </Link> */}
    //         <div
    //           className="deleteButton"
    //           // onClick={() => handleDelete(params.row.id)}
    //         >
    //           Delete
    //         </div>
    //         {/* <div
    //           className="activeButton"
    //           // onClick={() => handleDelete(params.row.id)}
    //         >
    //           Active
    //         </div>
    //         <div
    //           className="inactiveButton"
    //           // onClick={() => handleDelete(params.row.id)}
    //         >
    //           Inactive
    //         </div> */}
    //       </div>
    //     );
    //   },
    // },
  ];

  // const rows = [
  //   {
  //     id: 1,
  //     Category: "Feedback",
  //     Date: "18/11/2022",
  //     Notification: "Beautiful Day",
  //     // Action: "Edit, delete, active/inactive",
  //   },
  //   {
  //     id: 2,
  //     Category: "Feedback",
  //     Date: "18/11/2022",
  //     Notification: "Beautiful Day",
  //     // Action: "Edit, delete, active/inactive",
  //   },
  //   {
  //     id: 3,
  //     Category: "Feedback",
  //     Date: "18/11/2022",
  //     Notification: "Beautiful Day",
  //     // Action: "Edit, delete, active/inactive",
  //   },
  //   {
  //     id: 4,
  //     Category: "Feedback",
  //     Date: "18/11/2022",
  //     Notification: "Beautiful Day",
  //     // Action: "Edit, delete, active/inactive",
  //   },
  //   {
  //     id: 5,
  //     Category: "Feedback",
  //     Date: "18/11/2022",
  //     Notification: "Beautiful Day",
  //     // Action: "Edit, delete, active/inactive",
  //   },
  // ];

  const selectUser = (deviceId) => {
    setUserId(deviceId);

    setTimeout(() => {
      setModalShow(true);
    }, 1000);
  };

  const onSearch = (query) => {
    let filter = searchvalue.filter((item) =>
      Object.values(item).join("").toLowerCase().includes(query.toLowerCase())
    );
    setAllreport(filter);
  };
  return (
    <>
      <div className="">
        <div className="search_area">
          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            justifyContent={"flex-end"}
            sx={{ mb: 2 }}
          >
            <input
              type="text"
              class="form-control "
              id="Inputtext"
              style={{ width: "33%" }}
              placeholder="search area"
              onChange={(e) => {
                onSearch(e.target.value);
              }}
            />
            <Button>Search</Button>
          </Stack>
        </div>
        <Box
          sx={{
            height: 475,
            width: "100%",
            "@media (min-width:1200px)": { height: 740 },
          }}
          className="loadercenter"
        >
          <DataGrid
            rows={allreport}
            columns={columns}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            loading={isLoading}
          />
        </Box>
      </div>
      <AddNewModal
        show={modalShow}
        userid={userid}
        onHide={() => setModalShow(false)}
        // updateUserDetail={() => GetAllUser()}
      />
    </>
  );
};

export default ReceivedNotification;
