import axios from "axios";
import api from "./api";
import { tokens } from "./jwtTokenStroge";

export const adminlogin = async (data) => {
  const response = await api.request({
    url: "api/v1/admin/login",
    method: "POST",
    data,
  });
  console.log(response, "my rspon");
  return response;
};
export const getDashboard = async (data) => {
  const response = await api.request({
    url: "api/v1/admin",
    method: "get",
  });
  return response;
};
export const showUser = async (data) => {
  const response = await api.request({
    url: "api/v1/user",
    method: "get",
    data,
  });
  return response;
};
export const showCountry = async (data) => {
  const response = await api.request({
    url: "api/v1/coreCountry",
    method: "get",
    data,
  });
  return response;
};
export const showCities = async (data) => {
  const response = await api.request({
    url: `api/v1/coreCity/${data}`,
    method: "get",
  });
  return response;
};
export const creatcountry = async (data) => {
  const response = await api.request({
    url: "/api/v1/coreCountry",
    method: "POST",
    data,
  });
  return response;
};
export const getAllCountry = async (data) => {
  const response = await api.request({
    url: "api/v1/country",
    method: "get",
  });
  return response;
};
export const createCity = async (data) => {
  const response = await api.request({
    url: "api/v1/coreCity",
    method: "POST",
    data,
  });
  return response;
};
export const getcountrys = async (data) => {
  const response = await api.request({
    url: "api/v1/coreCountry/admin",
    method: "get",
  });

  return response;
};
export const getcitys = async (data) => {
  const response = await api.request({
    url: "api/v1/coreCity",
    method: "get",
  });

  return response;
};
export const createquote = async (data) => {
  const response = await api.request({
    url: "api/v1/quote",
    method: "POST",
    data,
  });
  return response;
};
export const getQuotes = async (data) => {
  const response = await api.request({
    url: "api/v1/quote",
    method: "get",
  });
  return response;
};

export const deletequotes = async (quoteId) => {
  const response = await api.request({
    url: `api/v1/quote/${quoteId}`,
    method: "delete",
  });
  return response;
};
export const deletecountry = async (countryId) => {
  const response = await api.request({
    url: `api/v1/coreCountry/${countryId}`,
    method: "delete",
  });
  return response;
};
export const deletecity = async (cityId) => {
  const response = await api.request({
    url: `api/v1/coreCity/${cityId}`,
    method: "delete",
  });

  return response;
};
export const createapi = async (data) => {
  const response = await api.request({
    url: "api/v1/api",
    method: "POST",
    data,
  });
  return response;
};
export const getAllapi = async (data) => {
  const response = await api.request({
    url: "api/v1/api",
    method: "get",
  });
  return response;
};
export const deleteapi = async (apiId) => {
  const response = await api.request({
    url: `api/v1/api/${apiId}`,
    method: "delete",
  });
  return response;
};
export const createtopic = async (data) => {
  const response = await api.request({
    url: "api/v1/topic",
    method: "POST",
    data,
  });
  return response;
};

export const getAllGroups = async () => {
  const response = await api.request({
    url: "api/v1/chatGroup/all-group",
    method: "get",
  });
  return response;
};

export const getAlltopic = async (data) => {
  const response = await api.request({
    url: "api/v1/topic",
    method: "get",
  });
  return response;
};
export const deletetopic = async (topicId) => {
  const response = await api.request({
    url: `api/v1/topic/${topicId}`,
    method: "delete",
  });
  return response;
};
export const createsubtopic = async (data) => {
  const response = await api.request({
    url: "api/v1/subTopic",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response;
};
export const getAllsubtopic = async (data) => {
  const response = await api.request({
    url: "api/v1/subTopic",
    method: "get",
  });
  return response;
};
export const deletesubtopic = async (subtopicId) => {
  const response = await api.request({
    url: `api/v1/subTopic/${subtopicId}`,
    method: "delete",
  });
  return response;
};
export const getOnlycountry = async (countryId) => {
  const response = await api.request({
    url: `api/v1/country/${countryId}`,
    method: "get",
  });
  return response;
};
export const getOnlyQuote = async (quoteId) => {
  const response = await api.request({
    url: `api/v1/quote/${quoteId}`,
    method: "get",
  });
  return response;
};
export const getOnlyApi = async (apiId) => {
  const response = await api.request({
    url: `api/v1/api/${apiId}`,
    method: "get",
  });
  return response;
};
export const getOnlytopic = async (topicId) => {
  const response = await api.request({
    url: `api/v1/topic/${topicId}`,
    method: "get",
  });
  return response;
};
export const getOnlysubtopic = async (subtopicid) => {
  const response = await api.request({
    url: `api/v1/subTopic/${subtopicid}`,
    method: "get",
  });
  return response;
};
export const updatecity = async (updateData) => {
  let data = {
    status: updateData.status,
  };

  const response = await api.request({
    url: `api/v1/coreCity/${updateData.cityId}`,
    method: "PATCH",
    data,
  });
  return response;
};
export const updatecountry = async (updateData) => {
  let data = {
    status: updateData.status,
  };

  const response = await api.request({
    url: `api/v1/coreCountry/${updateData.countryId}`,
    method: "PATCH",
    data,
  });
  return response;
};
export const updatequote = async (updateData) => {
  let data = {
    title: updateData.title,
    quoteOwner: updateData.quoteOwner,
    description: updateData.description,
    status: updateData.status,
  };

  const response = await api.request({
    url: `api/v1/quote/${updateData.quoteId}`,

    method: "PATCH",
    data,
  });
  return response;
};
export const updatetopic = async (updateData) => {
  let data = {
    name: updateData.name,
    status: updateData.status,
  };

  const response = await api.request({
    url: `api/v1/topic/${updateData.topicId}`,
    method: "PATCH",
    data,
  });
  return response;
};
export const updateSubtopic = async (updateData) => {
  let data = {
    topicId: updateData.topicId,
    subTopicName: updateData.subtopic,
    status: updateData.status,
  };

  const response = await api.request({
    url: `api/v1/subTopic/${updateData.subtopicId}`,
    method: "PATCH",
    data,
  });
  return response;
};
export const updateApis = async (updateData) => {
  let data = {
    name: updateData.name,
    apiLink: updateData.apiLink,
    validTill: updateData.validTill,
    status: updateData.status,
  };

  const response = await api.request({
    url: `api/v1/api/${updateData.apiId}`,
    method: "PATCH",
    data,
  });
  return response;
};
export const createUser = async (data) => {
  const response = await api.request({
    url: "api/v1/user",
    method: "POST",
    data,
  });

  return response;
};
export const getAllContact = async (data) => {
  const response = await api.request({
    url: "api/v1/contactus",
    method: "get",
  });
  return response;
};
export const createContactus = async (data) => {
  const response = await api.request({
    url: "api/v1/contactus",
    method: "POST",
    data,
  });

  return response;
};
export const getAllavtar = async (page) => {
  const response = await api.request({
    url: `api/v1/avatar/admin/${page}`,
    method: "get",
  });
  console.log(page);
  return response;
};
export const createAvtar = async (data) => {
  const response = await api.request({
    url: "api/v1/avatar/admin",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response;
};
export const deleteUser = async (data) => {
  const response = await api.request({
    url: `api/v1/admin/delete-user`,
    method: "delete",
    data,
  });
  return response;
};
export const deleteContact = async (contactId) => {
  const response = await api.request({
    url: `api/v1/contactus/delete-contact/${contactId}`,
    method: "delete",
  });
  return response;
};
export const getAllReport = async (data) => {
  const response = await api.request({
    url: "api/v1/report",
    method: "get",
  });
  return response;
};
export const deleteAvatar = async (avtartId) => {
  const response = await api.request({
    url: `api/v1/avatar/${avtartId}`,
    method: "delete",
  });
  return response;
};
export const getOnlyUser = async (userid) => {
  const response = await api.request({
    url: `api/v1/user/detail/${userid}`,
    method: "get",
  });

  return response;
};
export const getallcitybycontryid = async (countryid) => {
  const response = await api.request({
    url: `api/v1/city/cityByCountry/${countryid}`,
    method: "get",
  });

  return response;
};
export const updateuser = async (updateData) => {
  let data = {
    userId: updateData.userDetail._id,
    firstInitial: updateData.userDetail.firstInitial,
    lastInitial: updateData.userDetail.lastInitial,
    gender: updateData.userDetail.gender,
    country: updateData.userDetail.country,
    countryId: updateData.userDetail.countryId,
    countryName: updateData.userDetail.countryName,
    emoji: updateData.userDetail.emoji,
    city: updateData.userDetail.city,
    cityName: updateData.userDetail.cityName,
    status: updateData.status,
    phoneCode: updateData.userDetail.countryCode,
  };

  const response = await api.request({
    url: "api/v1/user",
    method: "PATCH",
    data,
  });

  return response;
};

export const getAllNotification = async () => {
  const response = await api.request({
    url: `api/v1/notification`,
    method: "get",
  });

  return response;
};

export const sendNotification = async (data) => {
  const response = await api.request({
    url: `api/v1/notification`,
    method: "post",
    data,
  });
  return response;
};

export const updateAdmin = async (data) => {
  const response = await api.request({
    url: `api/v1/admin`,
    method: "patch",
    data,
  });

  return response;
};

export const getAdminProfile = async (data) => {
  const response = await api.request({
    url: `api/v1/admin/profile`,
    method: "get",
  });
  return response;
};

export const getAllReportMgmt = async (data) => {
  const response = await api.request({
    url: "api/v1/reportmgmt",
    method: "get",
  });
  return response;
};
