import React, { useState, useEffect } from "react";
import "../../../Assets/Css/admin.css";
import { Form, InputGroup } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Upload from "../../../Assets/images/upload.png";
// import { styled } from "@mui/material/styles";
// import { uploadIcon } from "../../../utils/svg.file";
// import Editicon from "../../../Assets/images/editicon.png";
// import Profile from "../../../Assets/images/placeholder.png";
import {
  getAlltopic,
  createsubtopic,
  getOnlysubtopic,
  updateSubtopic,
} from "../../../Api/auth";
import Swal from "sweetalert2";
import Imagecropper from "./Imagecropper";

const Addsubtopic = (props) => {
  const [alltopic, setAlltopic] = useState([]);
  const [topic, setTopic] = useState("");
  const [subtopic, setSubtopic] = useState("");
  const [status, setStatus] = useState(true);
  const [modalimgShow, setModalImgShow] = useState(false);
  const [maleStatus, setMaleStatus] = useState(true);
  const [femaleStatus, setFemaleStatus] = useState(true);
  const [transgenderStatus, setTransgenderStatus] = useState(true);
  const [cropimg, setCropimg] = useState("");

  const submit1 = async () => {
    const response = await getAlltopic();

    const data = [];
    response.data.topic.map((el) =>
      el.name !== "Live Sports" && el.name !== "Trending" ? data.push(el) : null
    );
    if (response.remote === "success") {
      setAlltopic(data);
    } else {
      // setErrors(response.errors.errors);
      console.log(response.errors);
      // setIsLoading(false);
    }
  };
  useEffect(() => {
    submit1();
  }, []);
  // console.log(alltopic, "payload topic");

  const createSubtopic = async () => {
    let payload = new FormData();

    let err = false;
    if (topic === "" || topic === undefined) {
      err = true;
      Swal.fire({
        icon: "error",
        title: "Please Select Topic ",
        text: "Required field",
      });
    } else if (subtopic === "" || subtopic === undefined) {
      err = true;
      Swal.fire({
        icon: "error",
        title: "Please Enter Sub Topic Name",
        text: "Required field",
      });
    } else if (cropimg === "") {
      err = true;
      Swal.fire({
        icon: "error",
        title: "Please Select a image",
        text: "Required field",
      });
    } else if (!maleStatus && !femaleStatus && !transgenderStatus) {
      err = true;
      Swal.fire({
        icon: "error",
        title: "Please Select atleast one gender",
        text: "Required field",
      });
    }
    if (!err) {
      payload.append("image", cropimg);
      payload.append("topicId", topic);
      payload.append("status", status);
      payload.append("subTopicName", subtopic);
      payload.append("sportsId", "");
      payload.append("male", maleStatus);
      payload.append("female", femaleStatus);
      payload.append("transgender", transgenderStatus);

      const response = await createsubtopic(payload);
      if (response.remote == "success") {
        props.submit();
        setSubtopic("");
        setTopic("");
        setCropimg("");
        props.onHide();
        Swal.fire({
          icon: "success",
          title: "Sub topic created successfully",
        });
      } else {
        console.log(response.errors);
        props.onHide();
      }
    }
  };
  const getSubtopic = async (id) => {
    const response = await getOnlysubtopic(id);
    if (response.remote == "success") {
      setSubtopic(response.data.subTopic.subTopicName);
      setTopic(response.data.subTopic?.topicId._id);
      setStatus(response.data.subTopic.status);
    } else {
      console.log(response.errors);
    }
  };
  useEffect(() => {
    getSubtopic(props.subtopicid);
  }, [props.subtopicid]);

  const updateSubTOpic = async () => {
    // console.log(text, "discreption");
    let payload = {
      topicId: topic,
      status: status,
      subtopic: subtopic,
      subtopicId: props.subtopicid,
    };
    // console.log(payload, "value of update");
    const response = await updateSubtopic(payload);
    if (response.remote == "success") {
      props.submit();
      props.onHide();
    } else {
      // setErrors(response.errors.errors);
      console.log(response.errors);
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.subtopicid ? "Update sub topic" : "Add Sub Topic"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="container">
              <div className="Edit_profile_formpage">
                <div className="edit_profile_form_page ">
                  <form>
                    <div className="row">
                      <div class="mb-3 col-lg-6">
                        <label
                          for="exampleInputText"
                          class="form-label edit_profile_lebal"
                        >
                          Topic
                        </label>
                        {/* <label for="cars">Choose a car:</label> */}
                        <select
                          name="Topic"
                          id="Topic"
                          value={topic}
                          className="form-control edit_profile_input_area"
                          onChange={(e) => {
                            setTopic(e.target.value);
                          }}
                        >
                          <option disabled value="">
                            Select topic
                          </option>

                          {alltopic?.map((item) => {
                            return (
                              <option value={item._id}>{item.name}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="mb-3 col-lg-6">
                        <label
                          for="exampleInputText"
                          class="form-label edit_profile_lebal"
                        >
                          Sub Topic Name
                        </label>
                        <input
                          type="text"
                          maxLength={30}
                          class="form-control edit_profile_input_area"
                          id="exampleInputText1"
                          aria-describedby="emailHelp"
                          placeholder=" Sub Topic"
                          name="subtopic"
                          value={subtopic}
                          onChange={(e) => {
                            setSubtopic(e.target.value);
                          }}
                        />
                      </div>

                      <div className="mb-3 col-lg-6">
                        <label
                          for="exampleInputtext"
                          class="form-label edit_profile_lebal"
                        >
                          Status
                        </label>
                        <Form.Check
                          type="switch"
                          name="status"
                          id="custom-switch"
                          label="Active"
                          checked={status}
                          className="status_switch ms-3"
                          onChange={(e) => {
                            setStatus(e.target.checked);
                          }}
                        />

                        <div className="mt-3">
                          <h6>Select Gender</h6>
                          <label>
                            <input
                              checked={maleStatus}
                              type="checkbox"
                              className="me-2 "
                              value={maleStatus}
                              onChange={(value) => setMaleStatus(!maleStatus)}
                            />
                            Male
                          </label>
                          <label>
                            <input
                              checked={femaleStatus}
                              type="checkbox"
                              className="me-2 ms-2"
                              value={femaleStatus}
                              onChange={(value) =>
                                setFemaleStatus(!femaleStatus)
                              }
                            />
                            Female
                          </label>
                          <label>
                            <input
                              checked={transgenderStatus}
                              type="checkbox"
                              className="me-2 ms-2"
                              value={transgenderStatus}
                              onChange={(value) =>
                                setTransgenderStatus(!transgenderStatus)
                              }
                            />
                            Transgender
                          </label>
                        </div>
                      </div>
                      <div className="mb-3 col-lg-6">
                        <label
                          for="exampleFormControlTextarea1"
                          className="form-label edit_profile_lebal"
                        >
                          IMAGE
                        </label>
                        <div
                          className="addsubtopic_img"
                          onClick={() => setModalImgShow(true)}
                        >
                          <img
                            // cropimg ? URL.createObjectURL(cropimg) :
                            src={
                              cropimg ? URL.createObjectURL(cropimg) : Upload
                            }
                            alt="avtar"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="text-center">
                    <Button
                      className="mb-5 "
                      onClick={() => (
                        props.onHide(),
                        setSubtopic(""),
                        setTopic(""),
                        setStatus(false),
                        setCropimg("")
                      )}
                    >
                      Cancel{" "}
                    </Button>
                    <Button
                      className="mb-5 ms-5"
                      onClick={
                        props.subtopicid !== ""
                          ? updateSubTOpic
                          : createSubtopic
                      }
                    >
                      {props.subtopicid ? "Update" : "Add"}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Imagecropper
        show={modalimgShow}
        onHide={() => setModalImgShow(false)}
        setCropimg={setCropimg}
        cropimg={cropimg}
      />
    </>
  );
};

export default Addsubtopic;
