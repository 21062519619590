import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Avatar1 from "../Assets/images/avatar-1.jpg";
import "../Assets/Css/admin.css";
import { getAllNotification } from "../Api/auth";
import { baseURL } from "../Api/api";
import moment from "moment";

const Notifications = () => {
  const [notificationData, setNotificationData] = useState([]);
  useEffect(() => {
    getnotificationData();
  }, []);
  const getnotificationData = async () => {
    const response = await getAllNotification();

    if (response.remote == "success") {
      setNotificationData(response.data.data);
    }
  };

  return (
    <div>
      <div className="topnav-dropdown-header">
        <div className="noti-content">
          <Row>
            <Col sm={12} md={6} lg={6}>
              <span className="notification-title">Notifications</span>
            </Col>
            {/* <Col sm={12} md={6} lg={6}>
              <a href="#" className="clear-noti">
                Clear All
              </a>
            </Col> */}
          </Row>

          <ul className="notification-list">
            {notificationData.slice(0, 4).map((el, index) => {
              return (
                <li className="notification-message">
                  <div className="media d-flex">
                    <span className="avatar flex-shrink-0">
                      {el?.notificationSenderId ? (
                        <img
                          src={baseURL + el?.notificationSenderId?.profileImage}
                        />
                      ) : (
                        <img src={require("../Assets/images/avatar-1.jpg")} />
                      )}
                    </span>
                    <div className="media-body flex-grow-1">
                      <p className="noti-details">
                        <span className="noti-title">
                          {el?.notificationSenderId?.firstInitial}
                          {el?.notificationSenderId?.lastInitial}{" "}
                        </span>
                        {el.message}
                        <span className="noti-title">
                          {" "}
                          {el?.notifiationReceiverId?.firstInitial}
                          {el?.notifiationReceiverId?.lastInitial}
                        </span>
                      </p>
                      <p className="noti-time">
                        <span className="notification-time">
                          {moment(el?.createdAt).format("DD-MMM")}
                        </span>
                      </p>
                    </div>
                  </div>
                </li>
              );
            })}
            <div className="topnav-dropdown-footer text-center">
              <a href="SendNotification">View all Notifications</a>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
