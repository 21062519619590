import "../../../Assets/Css/admin.css";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import Upload from "../../../Assets/images/user.png";
import Imagecropper from "./Imagecropper";
import { createAvtar } from "../../../Api/auth";
import Swal from "sweetalert2";

const AddAvtar = (props) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [cropimg, setCropimg] = useState("");
  const [modalShow, setModalShow] = useState(false);

  // console.log(cropimg, "imgeeeeeeee");

  const createavtart = async () => {
    let payload = new FormData();
    payload.append("image", cropimg);

    const response = await createAvtar(payload);

    if (response.remote == "success") {
      props.submit();
      props.onHide();
    } else {
      console.log(response.errors);
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Avatar
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="container">
              <div className="">
                <div className="text-center pt-3">
                  <form>
                    <div className="row">
                      {/* <div className="mb-3 col-lg-6">
                      <label
                        for="exampleInputText"
                        class="form-label edit_profile_lebal"
                      >
                        TITLE
                      </label>
                      <input
                        type="text"
                        class="form-control edit_profile_input_area"
                        id="exampleInputText"
                        aria-describedby="emailHelp"
                        placeholder="Enter Title"
                        // value={profileusername}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div> */}
                      <div className="mb-3">
                        {/* <label
                          for="exampleFormControlTextarea1"
                          className="form-label edit_profile_lebal"
                        >
                          IMAGE
                        </label> */}
                        <div
                          className="addavtar_img"
                          onClick={() => setModalShow(true)}
                        >
                          <img
                            src={
                              cropimg ? URL.createObjectURL(cropimg) : Upload
                            }
                            alt="avtar"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="">
                    <Button
                      className="mb-5 ms-2"
                      onClick={() => props.onHide()}
                    >
                      Cancel
                    </Button>
                    <Button className="mb-5 ms-3" onClick={createavtart}>
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Imagecropper
        show={modalShow}
        onHide={() => setModalShow(false)}
        setCropimg={setCropimg}
        cropimg={cropimg}
      />
    </>
  );
};

export default AddAvtar;
