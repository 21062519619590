import React, { useState, useEffect } from "react";
import "../../../Assets/Css/admin.css";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import { uploadIcon } from "../../../utils/svg.file";
import Editicon from "../../../Assets/images/editicon.png";
import Profile from "../../../Assets/images/placeholder.png";
import { createquote, getOnlyQuote, updatequote } from "../../../Api/auth";
import Swal from "sweetalert2";

const Addquote = (props) => {
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [status, setStatus] = useState(false);
  const [owner, setOwner] = useState("");

  const handelquote = async () => {
    let err = false;
    if (title === "" || title === undefined) {
      err = true;
      Swal.fire({
        icon: "error",
        title: "Please Enter Title ",
        text: "Required field",
      });
    }
    if (owner === "" || owner === undefined) {
      err = true;
      Swal.fire({
        icon: "error",
        title: "Please Enter Qwner Name ",
        text: "Required field",
      });
    }
    if (text === "" || text === undefined) {
      err = true;
      Swal.fire({
        icon: "error",
        title: "Please Enter description  ",
        text: "Required field",
      });
    }

    if (!err) {
      let payload = {
        title: title,
        quoteOwner: owner,
        description: text,
        status: status,
      };

      const response = await createquote(payload);
      if (response.remote == "success") {
        props.onHide();
        setTitle("");
        setText("");
        setOwner("");
        setStatus(false);
        props.submit();
      } else {
        // setErrors(response.errors.errors);
        console.log(response.errors);
      }
    }
  };

  const getquote = async (id) => {
    const response = await getOnlyQuote(id);
    if (response.remote == "success") {
      setTitle(response.data.quote.title);
      setText(response.data.quote.description);
      setOwner(response.data.quote.quoteOwner);
      setStatus(response.data.quote.status);

      // setOnlycountry(response.data.country.countryId.name);
    } else {
      console.log(response.errors);
    }
  };
  useEffect(() => {
    getquote(props.quoteid);
  }, [props.quoteid]);

  const updatquote = async () => {
    let payload = {
      title: title,
      quoteOwner: owner,
      description: text,
      status: status,
      quoteId: props.quoteid,
    };

    const response = await updatequote(payload);
    if (response.remote == "success") {
      props.submit();
      props.onHide();
    } else {
      // setErrors(response.errors.errors);
      console.log(response.errors);
    }
  };

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.quoteid ? "Update Quote" : "Add New Quote"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="container">
            <div className="Edit_profile_formpage">
              <div className="edit_profile_form_page ">
                <form>
                  <div className="row">
                    <div className="mb-3 col-lg-6">
                      <label
                        for="exampleInputText"
                        class="form-label edit_profile_lebal"
                      >
                        Title
                      </label>
                      <input
                        type="text"
                        class="form-control edit_profile_input_area"
                        id="exampleInputText"
                        aria-describedby="emailHelp"
                        value={title}
                        placeholder="Title"
                        name="title"
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                    <div className="mb-3 col-lg-6">
                      <label
                        for="exampleInputText"
                        class="form-label edit_profile_lebal"
                      >
                        Quote Owner
                      </label>
                      <input
                        type="text"
                        class="form-control edit_profile_input_area"
                        id="exampleInputText"
                        aria-describedby="emailHelp"
                        placeholder=" Quote Owner"
                        name="Owner"
                        value={owner}
                        onChange={(e) => setOwner(e.target.value)}
                      />
                    </div>
                    <div className="mb-3 col-lg-6">
                      <label
                        for="exampleInputtext"
                        class="form-label edit_profile_lebal"
                      >
                        Status
                      </label>
                      <Form.Check
                        type="switch"
                        name="status"
                        id="custom-switch"
                        label="Active"
                        checked={status}
                        className="status_switch ms-3"
                        onChange={(e) => setStatus(e.target.checked)}
                      />
                    </div>
                  </div>

                  <div class="mb-3">
                    <label
                      for="exampleFormControlTextarea1"
                      className="form-label edit_profile_lebal"
                    >
                      Description
                    </label>
                    <textarea
                      className="form-control edit_profile_input_area"
                      id="exampleFormControlTextarea1"
                      placeholder="description"
                      rows="3"
                      name="text"
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                    ></textarea>
                  </div>
                </form>
                <div className="text-center">
                  <Button
                    className="mb-5 "
                    onClick={() => (
                      props.onHide(),
                      setTitle(""),
                      setText(""),
                      setOwner(""),
                      setStatus(false)
                    )}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="mb-5 ms-3"
                    onClick={props.quoteid !== "" ? updatquote : handelquote}
                  >
                    {props.quoteid ? "Update" : "Add"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Addquote;
