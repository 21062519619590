import "./App.css";
import { Route, Routes } from "react-router-dom";
import Layout from "./AdminPanel/Layout";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./AdminPanel/Login";

import "react-toastify/dist/ReactToastify.css";
import PreLoader from "./AdminPanel/Component/preloader";
import { useEffect, useState } from "react";
import LandingPage from "./landingpage";
function App() {
  const [loading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />

        <Route path="/*" element={<Layout />} />
        <Route path="/home" element={<LandingPage />} />
      </Routes>
      {loading && <PreLoader />}
    </div>
  );
}

export default App;
