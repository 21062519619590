import { Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { getAdminProfile, updateAdmin } from "../Api/auth";

export default function Profile() {
  let navigate = useNavigate();
  const [profileData, setProfileData] = useState({
    name: "",
    email: "",
  });

  useEffect(() => {
    adminData();
  }, []);

  const adminData = async () => {
    const resp = await getAdminProfile();
    if (resp.remote == "success") {
      setProfileData({
        name: resp.data.data.name,
        email: resp.data.data.email,
      });
    }
  };

  const updateProfile = async () => {
    if (
      profileData.name == "" ||
      profileData.email == "" ||
      profileData.password == "" ||
      profileData.cpassword == ""
    ) {
      Swal.fire({
        icon: "error",
        title: "All field are mandatory",
      });
    } else if (profileData.password != profileData.cpassword) {
      Swal.fire({
        icon: "error",
        title: "Confirm password not matched",
      });
    } else {
      const resp = await updateAdmin(profileData);
      if (resp.remote == "success") {
        Swal.fire({
          icon: "success",
          title: "Profile updated successfully",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(-1);
          }
        });
      }
    }
  };

  return (
    <div>
      <div className="profilePage">
        <Form>
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter name"
              value={profileData.name}
              onChange={(e) =>
                setProfileData({ ...profileData, name: e.target.value })
              }
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              disabled={true}
              placeholder="Enter email"
              value={profileData.email}
              onChange={(e) =>
                setProfileData({ ...profileData, email: e.target.value })
              }
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Enter new password</Form.Label>
            <Form.Control
              type="password"
              placeholder="********"
              onChange={(e) =>
                setProfileData({ ...profileData, password: e.target.value })
              }
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="confirm password"
              placeholder="********"
              onChange={(e) =>
                setProfileData({ ...profileData, cpassword: e.target.value })
              }
            />
          </Form.Group>
        </Form>
        <Button
          variant="contained"
          type="submit"
          className="admin_login_button"
          onClick={updateProfile}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}
