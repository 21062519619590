import React, { useState, useEffect } from "react";
import "../../../Assets/Css/admin.css";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
// import { styled } from "@mui/material/styles";
// import { uploadIcon } from "../../../utils/svg.file";
// import Editicon from "../../../Assets/images/editicon.png";
// import Profile from "../../../Assets/images/placeholder.png";
import { createtopic, getOnlytopic, updatetopic } from "../../../Api/auth";
import Swal from "sweetalert2";

const Addnewtopic = (props) => {
  const [topicname, setTopicname] = useState("");
  const [status, setStatus] = useState(true);

  console.log(props.topicid, "topic id");

  // setTopicname("");
  // setStatus(false);

  const createTopic = async () => {
    let err = false;
    if (topicname === "" || topicname === undefined) {
      err = true;
      Swal.fire({
        icon: "error",
        title: "Please Enter Topic Name",
        text: "Required field",
      });
    }
    if (!err) {
      let payload = {
        name: topicname,
        status: status,
      };
      const response = await createtopic(payload);

      if (response.remote == "success") {
        props.onHide();
        props.submit();
      } else {
        console.log(response.errors);
      }
    }
  };
  const getapi = async (id) => {
    const response = await getOnlytopic(id);
    if (response.remote == "success") {
      setTopicname(response.data.topic.name);
      setStatus(response.data.topic.status);

      // setOnlycountry(response.data.country.countryId.name);
    } else {
      console.log(response.errors);
    }
  };
  useEffect(() => {
    getapi(props.topicid);
  }, [props.topicid]);

  const updateTOpic = async () => {
    // console.log(text, "discreption");
    let payload = {
      name: topicname,
      status: status,
      topicId: props.topicid,
    };
    // console.log(payload, "value of update");
    const response = await updatetopic(payload);
    if (response.remote == "success") {
      props.submit();

      props.onHide();
    } else {
      // setErrors(response.errors.errors);
      console.log(response.errors);
    }
  };

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.topicid !== "" ? "Update Topic " : "Add New Topic"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="container">
            <div className="Edit_profile_formpage">
              <div className="edit_profile_form_page ">
                <form>
                  <div className="row">
                    <div className="mb-3 col-lg-6">
                      <label
                        for="exampleInputText"
                        class="form-label edit_profile_lebal"
                      >
                        Topic Name
                      </label>
                      <input
                        maxLength={30}
                        type="text"
                        class="form-control edit_profile_input_area"
                        id="exampleInputText"
                        aria-describedby="emailHelp"
                        placeholder="Topic"
                        value={topicname}
                        name="topicname"
                        onChange={(e) => setTopicname(e.target.value)}
                        // value={profileusername}
                      />
                    </div>

                    <div className="mb-3 col-lg-6">
                      <label
                        for="exampleInputtext"
                        class="form-label edit_profile_lebal"
                      >
                        Status
                      </label>
                      <Form.Check
                        type="switch"
                        name="status"
                        checked={status}
                        id="custom-switch"
                        label="Active"
                        className="status_switch ms-3"
                        onChange={(e) => setStatus(e.target.checked)}
                      />
                    </div>
                  </div>
                </form>
                <div className="text-center">
                  <Button
                    className="mb-5 "
                    onClick={() => {
                      props.onHide();
                      setTopicname("");
                      setStatus(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="mb-5 ms-3"
                    onClick={() => {
                      props.topicid !== "" ? updateTOpic() : createTopic();
                      setTopicname("");
                    }}
                  >
                    {props.topicid ? "Update" : " Add"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Addnewtopic;
