import React, { useState, useEffect } from "react";
import "../../../Assets/Css/admin.css";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import { uploadIcon } from "../../../utils/svg.file";
import Editicon from "../../../Assets/images/editicon.png";
import Profile from "../../../Assets/images/placeholder.png";
import Upload from "../../../Assets/images/camera.png";
import Imageshow from "./imageshow";
import {
  getallcitybycontryid,
  getAllCountry,
  getAllavtar,
  getOnlyUser,
  updateuser,
} from "../../../Api/auth";
import { SendToMobile } from "@mui/icons-material";
import { baseURL } from "../../../Api/api";

const AddNewModal = (props) => {
  // console.log(props.userid, "user diviceid");
  const [allcountry, setAllcountry] = useState([]);
  const [cityname, setCityname] = useState([]);
  const [selectcity, setSelectCity] = useState([]);
  const [allavtar, setAllavtar] = useState([]);
  const [modalavatarShow, setModalavatarShow] = useState(false);
  const [imgshow, setImgshow] = useState("");
  const [details, setDetails] = useState("");
  const [userDetail, setUserDetail] = useState({});
  // const [firstInitial, setFirstInitial] = useState("");
  // const [lastInitial, setLastInitial] = useState("");
  // const [userImage, setUserImage] = useState("");
  // const [country, SetCountry] = useState("");
  // const [city, SetCity] = useState("");
  const [status, setStatus] = useState(true);
  // const [gender, setGender] = useState("");
  // const [mobile, SetMobile] = useState("");

  // console.log(props, "props hai ");
  useEffect(() => {
    getuser(props.userid);
  }, [props.userid]);

  const getuser = async (id) => {
    // console.log(id, "user diviceid");
    const response = await getOnlyUser(id);
    if (response.remote == "success") {
      setUserDetail(response.data.user.user);
      // setFirstInitial(response.data.user.user.firstInitial);
      // setLastInitial(response.data.user.user.lastInitial);
      // setUserImage(response.data.user.user.profileImage);
      // SetCountry(response.data.user.user.country);
      // SetCity(response.data.user.user.city);
      setStatus(response.data.user.user.isActive);
      // setGender(response.data.user.user.gender);
      // SetMobile(response.data.user.user.phone);

      // setDetails(response.data.user);
    } else {
      console.log(response.errors);
    }
  };

  const getAllAVATAR = async () => {
    const response = await getAllavtar();
    if (response.remote == "success") {
      // setCityname(response.data.city);
      // console.log(response.data.allAvatar, "Avtar");
      setAllavtar(response.data.allAvatar);
    } else {
      console.log(response.errors);
    }
  };

  const submit = async () => {
    const response = await getAllCountry();
    if (response.remote == "success") {
      // console.log(response.data.countries, "hello");
      setAllcountry(response.data.countries);
    } else {
      console.log(response.errors);
    }
  };

  useEffect(() => {
    submit();
    getAllAVATAR();
  }, []);

  const getAllCity = async (id) => {
    const response = await getallcitybycontryid(id);
    if (response.remote == "success") {
      setCityname(response.data.city);
      // console.log(response.data.city, "city or country");
    } else {
      console.log(response.errors);
    }
  };

  const updateUserStatus = async (status) => {
    const response = await updateuser({ userDetail, status });
    props.updateUserDetail();
  };

  return (
    <>
      <Modal
        className="scrolldiv"
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            View Profile
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="container">
              <div className="profileedit_start">
                <div className="Profile_edit_header">User Details</div>
              </div>
              <div className="Edit_profile_formpage ">
                <div
                  className="addavtar_img mb-3 ms-4"
                  // onClick={() => setModalavatarShow(true)}
                >
                  <img
                    src={
                      userDetail?.profileImage
                        ? `${baseURL}${userDetail.profileImage}`
                        : Upload
                    }
                    alt="avtar"
                  />
                </div>

                <div className="edit_profile_form_page ">
                  <form>
                    <div className="row">
                      <div className="mb-3 col-lg-6">
                        <label
                          for="exampleInputText"
                          class="form-label edit_profile_lebal"
                        >
                          FirstInitial
                        </label>
                        <input
                          disabled={true}
                          type="text"
                          name="firstname"
                          className="form-control edit_profile_input_area"
                          id="exampleInputText"
                          aria-describedby="emailHelp"
                          placeholder="First Name"
                          defaultValue={userDetail?.firstInitial}
                        />
                      </div>
                      <div className="mb-3 col-lg-6">
                        <label
                          for="exampleInputtext"
                          class="form-label edit_profile_lebal"
                        >
                          LastInitial
                        </label>
                        <input
                          disabled={true}
                          type="text"
                          class="form-control edit_profile_input_area"
                          id="exampleInputtext"
                          placeholder="Last name"
                          defaultValue={userDetail?.lastInitial}
                          // onChange={(e) => setProfilename(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div class="mb-3 col-lg-6">
                        <label
                          for="exampleInputtext"
                          class="form-label edit_profile_lebal"
                        >
                          Phone number
                        </label>
                        <input
                          disabled={true}
                          type="number"
                          class="form-control edit_profile_input_area"
                          id="exampleInputnumber"
                          placeholder="+1 9999999999"
                          defaultValue={userDetail?.phone}
                          // onChange={(e) => setProfilephone(e.target.value)}
                        />
                      </div>

                      <div className="mb-3 col-lg-6 ">
                        <label
                          for="exampleInputText"
                          class="form-label edit_profile_lebal status_switch_button"
                        >
                          Member Status
                        </label>
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="Active"
                          checked={status}
                          className="status_switch ms-3"
                          onChange={(e) => {
                            updateUserStatus(e.target.checked);
                            setStatus(!status);
                          }}
                        />
                      </div>
                      <div class="mb-3 col-lg-6">
                        <label
                          for="exampleInputtext"
                          class="form-label edit_profile_lebal"
                        >
                          Gender
                        </label>
                        {["radio"].map((type) => (
                          <div key={`inline-${type}`} className="d-flex ps-3">
                            <Form.Check
                              disabled={true}
                              inline
                              label="Male"
                              name="Gender"
                              type={type}
                              checked={userDetail?.gender === "male"}
                              id={`inline-${type}`}
                            />
                            <Form.Check
                              inline
                              label="Female"
                              name="Gender"
                              checked={userDetail?.gender === "female"}
                              type={type}
                              id={`inline-${type}`}
                            />
                            <Form.Check
                              inline
                              label="Xgender"
                              name="Gender"
                              checked={userDetail?.gender === "xgender"}
                              type={type}
                              id={`inline-${type}`}
                            />
                          </div>
                        ))}
                      </div>

                      <div class="mb-3 col-lg-6">
                        <label
                          for="exampleInputText"
                          class="form-label edit_profile_lebal"
                        >
                          Country
                        </label>
                        <input
                          disabled={true}
                          type="text"
                          name="country"
                          className="form-control edit_profile_input_area"
                          id="exampleInputText"
                          aria-describedby="emailHelp"
                          placeholder="Country"
                          defaultValue={userDetail?.countryName}
                        />
                        {/* <select
                          disabled={true}
                          name="Country"
                          id="country"
                          className="form-control edit_profile_input_area"
                          value={userDetail?.country}
                          onChange={(e) => {
                            // console.log("value --- ", e.target.value);
                            // SetCountry(e.target.value);
                          }}
                        > */}
                        {/* {allcountry?.map((item) => {
                            return (
                              <option value={item._id}>
                                {item.countryId.name}
                              </option>
                            );
                          })} */}
                        {/* </select> */}
                      </div>
                      <div class="mb-3 col-lg-6">
                        <label
                          for="exampleInputtext"
                          class="form-label edit_profile_lebal"
                        >
                          State
                        </label>
                        <input
                          disabled={true}
                          type="text"
                          name="State"
                          className="form-control edit_profile_input_area"
                          id="exampleInputText"
                          aria-describedby="emailHelp"
                          placeholder="State name"
                          defaultValue={userDetail?.cityName}
                        />
                      </div>

                      <div class="mb-3 col-lg-6">
                        <label
                          for="exampleInputtext"
                          class="form-label edit_profile_lebal"
                        >
                          Last joined group
                        </label>
                        <input
                          disabled={true}
                          type="text"
                          name="groupname"
                          className="form-control edit_profile_input_area"
                          id="exampleInputText"
                          aria-describedby="emailHelp"
                          placeholder=""
                          defaultValue={userDetail?.lastGroupName}
                        />
                      </div>
                    </div>
                  </form>
                  <div className="text-center">
                    <Button
                      className="mb-5 "
                      onClick={() => {
                        props.onHide();
                      }}
                    >
                      Back to User Page
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Imageshow
        show={modalavatarShow}
        onHide={() => setModalavatarShow(false)}
        setImgshow={setImgshow}
        imgshow={imgshow}
      />
    </>
  );
};

export default AddNewModal;
