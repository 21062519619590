import { Box, Container, Grid } from "@mui/material";
import "../landingpage/landingpage.css";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
const LandingPage = () => {
  return (
    <>
      <Box className="homepage">
        <div className="bg-overlay"></div>
        <Box sx={{ position: "relative", zIndex: 10 }}>
          <Container>
            <Grid container spacing={0}>
              <Grid item lg={8} xs={12}>
                <div className="sale-item py-5">
                  <h4 className="sale d-inline-block">This domain</h4>
                  <h1 className="head py-3">Huddle.com</h1>
                  <h4>Hurry and make an offer!</h4>
                  {/* <span>
                    Best Offer: <b class="font-weight-bold">$550</b>
                  </span> */}
                  <p className="text-white-50 py-1 pe-md-2">
                    Replacing a maintains the amount of lines. When replacing a
                    selection. help agencies to define their new business
                    objectives and then create.
                  </p>
                </div>
                <ul className="list-unstyled d-inline-block m-0 pt-2">
                  <li class="d-inline-block me-lg-5">
                    <PhoneIcon />
                    <a className="turquoise" href="tel:0000000000">
                      +91 123 444 5000
                    </a>
                  </li>
                  <li class="d-inline-block">
                    <MailIcon />
                    <a className="turquoise" href="mailto:Huddle@domain.com">
                      Huddle@domain.com
                    </a>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
};
export default LandingPage;
