import {
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  OutlinedInput,
} from "@mui/material";
import "../Assets/Css/admin.css";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { adminlogin } from "../Api/auth";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

const Login = () => {
  const [errors, setErrors] = useState("");
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Field should contain a valid e-mail")
        .required("Email Must be Required"),

      password: Yup.string().required("Password Must be Required"),
    }),

    onSubmit: async (values) => {
      const response = await adminlogin(values);

      if (response.remote == "success") {
        navigate("/dashboard");
      } else {
        setErrors(response.errors.errors);
      }
    },
  });

  return (
    <>
      <div className="mt-4">
        <Grid container>
          <Grid items xs={4} className="m-auto">
            <div className="admin_login_page">
              <h1 className="text-center fw-normal mb-4 fs-2">Login Page</h1>
              <Form onSubmit={formik.handleSubmit}>
                <FormGroup>
                  <FormLabel>User</FormLabel>
                  <FormControl>
                    <OutlinedInput
                      placeholder="enter User"
                      className="login-input-search"
                      type="email"
                      name="email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />
                  </FormControl>
                </FormGroup>
                <span className="text-danger">{formik.errors.email}</span>
                <FormGroup>
                  <FormLabel className="mt-3">Password</FormLabel>
                  <FormControl>
                    <OutlinedInput
                      placeholder="enter Password"
                      type="password"
                      name="password"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                    />
                  </FormControl>
                </FormGroup>
                <span className="text-danger">{formik.errors.password}</span>
                <span className="text-danger">{errors}</span>
                <div className="text-center mt-4">
                  <Button
                    variant="contained"
                    type="submit"
                    // LinkComponent={Link}
                    // to="/manageuser"
                    className="admin_login_button"
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Login;
