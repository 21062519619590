import React, { useState } from "react";
import "../../../Assets/Css/admin.css";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { styled } from "@mui/material/styles";
import { uploadIcon } from "../../../utils/svg.file";
import Editicon from "../../../Assets/images/editicon.png";
import Profile from "../../../Assets/images/placeholder.png";
import { sendNotification } from "../../../Api/auth";

const Sendnoti = (props) => {
  const [data, setData] = useState({ title: "", message: "" });
  const sendData = async () => {
    if (data.title == "") {
      Swal.fire({
        icon: "error",
        title: "Please enter title",
        text: "Required field",
      });
    } else if (data.message == "")
      Swal.fire({
        icon: "error",
        title: "Please enter message",
        text: "Required field",
      });
    else {
      const resp = await sendNotification(data);

      if (resp.remote == "success") {
        Swal.fire({
          icon: "success",
          title: "Send notification successfully",
          // text: "Required field",
        });
      }
    }
  };

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Send notification to all
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="container">
            <div className="">
              <div className="">
                <form>
                  <div className="row">
                    <div className="mb-3 col-lg-12">
                      <label
                        for="exampleInputText"
                        class="form-label edit_profile_lebal"
                      >
                        Title
                      </label>
                      <textarea
                        type="text"
                        class="form-control edit_profile_input_area"
                        id="exampleInputAddress"
                        aria-describedby="emailHelp"
                        placeholder="type title here.."
                        value={data.title}
                        onChange={(e) =>
                          setData({ ...data, title: e.target.value })
                        }
                      />
                    </div>
                  </div>

                  <div class="mb-3">
                    <label
                      for="exampleFormControlTextarea1"
                      className="form-label edit_profile_lebal"
                    >
                      Message
                    </label>
                    <textarea
                      className="form-control edit_profile_input_area"
                      id="exampleFormControlTextarea1"
                      placeholder="Message"
                      rows="3"
                      value={data.message}
                      onChange={(e) =>
                        setData({ ...data, message: e.target.value })
                      }
                    ></textarea>
                  </div>
                </form>
                <div className="text-center">
                  <Button className="mb-5 " onClick={() => props.onHide()}>
                    Cancle
                  </Button>
                  <Button onClick={sendData} className="mb-5 ms-2 ">
                    Send{" "}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Sendnoti;
