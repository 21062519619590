import React, { useState, useEffect } from "react";
import "../../../Assets/Css/admin.css";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import { uploadIcon } from "../../../utils/svg.file";
import Editicon from "../../../Assets/images/editicon.png";
import Profile from "../../../Assets/images/placeholder.png";
import { showCountry, creatcountry, getOnlycountry } from "../../../Api/auth";

const Addcountry = (props) => {
  const [allcountry, setAllcountry] = useState([]);
  const [countryDetail, setCountryDetail] = useState({
    countryName: "",
    emoji: "",
    iso: "",
    phoneCode: "",
  });
  const [countryid, setCountryid] = useState("");
  const [status, setStatus] = useState(false);
  const [onlycountry, setOnlycountry] = useState([]);

  // console.log(props.countryid, "ckjdgadgfdfg");
  const submit = async () => {
    const response = await showCountry();
    if (response.remote == "success") {
      // setAllcountry(response.data.country);
    } else {
      console.log(response.errors);
    }
  };
  useEffect(() => {
    submit();
  }, []);

  const createcountry = async () => {
    // let payload = {
    //   countryId: countryid,
    //   status: status,
    // };
    const response = await creatcountry(countryDetail);

    if (response.remote == "success") {
      props.onHide();
      props.getcountry();
    } else {
      console.log(response.errors);
    }
  };

  const getcountry = async (id) => {
    const response = await getOnlycountry(id);
    if (response.remote == "success") {
      setOnlycountry(response.data.country.countryId.name);
    } else {
      console.log(response.errors);
    }
  };
  useEffect(() => {
    getcountry(props.countryid);
  }, [props.countryid]);

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Country
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="container">
            <div className="Edit_profile_formpage p-3">
              <div className="edit_profile_form_page ">
                <div className="row">
                  <div class="mb-3 col-lg-6">
                    <label for="exampleInputText" class="form-label ">
                      Country
                    </label>

                    {/* <select
                      name="countryId"
                      id="countryId"
                      className="form-control edit_profile_input_area"
                      // defaultValue={onlycountry}
                      onChange={(e) => setCountryid(e.target.value)}
                    ></select> */}
                    <input
                      type="text"
                      class="form-control edit_profile_input_area"
                      id="exampleInputText1"
                      aria-describedby="emailHelp"
                      placeholder="Type country name"
                      name="country name"
                      value={countryDetail.countryName}
                      onChange={(e) => {
                        setCountryDetail({
                          ...countryDetail,
                          countryName: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="mb-3 col-lg-6">
                    {" "}
                    <label for="exampleInputText" class="form-label ">
                      Type Emoji
                    </label>
                    <input
                      type="text"
                      class="form-control edit_profile_input_area"
                      id="exampleInputText1"
                      aria-describedby="emailHelp"
                      placeholder="Type emoji"
                      name="emoji"
                      value={countryDetail.emoji}
                      onChange={(e) => {
                        setCountryDetail({
                          ...countryDetail,
                          emoji: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="mb-3 col-lg-6">
                    {" "}
                    <label for="exampleInputText" class="form-label ">
                      Country ISO
                    </label>
                    <input
                      type="text"
                      class="form-control edit_profile_input_area"
                      id="exampleInputText1"
                      aria-describedby="emailHelp"
                      placeholder="Type country iso"
                      name="iso"
                      value={countryDetail.iso}
                      onChange={(e) => {
                        setCountryDetail({
                          ...countryDetail,
                          iso: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="mb-3 col-lg-6">
                    <label for="exampleInputText" class="form-label ">
                      Country Phone Code
                    </label>
                    <input
                      type="text"
                      class="form-control edit_profile_input_area"
                      id="exampleInputText1"
                      aria-describedby="emailHelp"
                      placeholder="Type country phone code"
                      name="phonecode"
                      value={countryDetail.phoneCode}
                      onChange={(e) => {
                        setCountryDetail({
                          ...countryDetail,
                          phoneCode: e.target.value,
                        });
                      }}
                    />
                  </div>

                  <div className="mb-3 col-lg-6 d-flex align-items-center">
                    <label for="exampleInputtext" class="form-label ">
                      Status
                    </label>
                    <Form.Check
                      type="switch"
                      name="status"
                      id="custom-switch"
                      label="Active"
                      className="status_switch ms-2"
                      onChange={(e) => setStatus(e.target.checked)}
                    />
                  </div>
                </div>

                <div className="text-center">
                  <Button className="mb-5 " onClick={() => props.onHide()}>
                    Cancle
                  </Button>
                  <Button
                    className="mb-5 ms-3"
                    // type="submit"
                    onClick={createcountry}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Addcountry;
