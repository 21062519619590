import { React, useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import "../../Assets/Css/admin.css";
import { Button } from "react-bootstrap";
import Addnewtopic from "./Modal/Addnewtopic";
import { getAlltopic, deletetopic } from "../../Api/auth";
import moment from "moment";
import Swal from "sweetalert2";
import { Stack, Box } from "@mui/material";

const TopicsManagement = () => {
  const [modalShow, setModalShow] = useState(false);
  const [alltopic, setAlltopic] = useState([]);
  const [topicid, setTopicId] = useState("");
  const [searchvalue, setSearchValue] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const submit = async () => {
    const response = await getAlltopic();
    if (response.remote == "success") {
      setSearchValue(
        response.data.topic.map((topic, idx) => {
          return {
            _id: topic._id,
            id: idx + 1,
            date: moment(topic.createdAt).format("DD-MM-YYYY"),
            topic: topic.name,
            status: topic.status,
          };
        })
      );
      setAlltopic(
        response.data.topic.map((topic, idx) => {
          return {
            _id: topic._id,
            id: idx + 1,
            date: moment(topic.createdAt).format("DD-MM-YYYY"),
            topic: topic.name,
            status: topic.status,
          };
        })
      );
      setIsLoading(false);
    } else {
      // setErrors(response.errors.errors);
      console.log(response.errors);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    submit();
  }, []);

  const deleteTopic = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deletetopic(id);
        if (response.remote == "success") {
          submit();
        } else {
          // setIsLoading(false);
        }
        Swal.fire("Deleted!", "Your record  has been deleted.", "success");
      }
    });
  };

  const columns = [
    { field: "id", headerName: "Id", width: 70 },
    { field: "date", headerName: "Date", width: 130 },
    { field: "topic", headerName: "Topic Name", width: 130 },
    {
      field: "status",
      headerName: "Status",
      width: 160,
      renderCell: (params) => {
        return (
          <div
            className={`cellWithStatus ${
              params.row.status ? "active" : "inactive"
            }`}
          >
            {params.row.status ? "Active" : "Inactive"}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 300,
      renderCell: (params) => {
        return params.row._id == "63ce5aac50ef9809c6c36bfb" ||
          params.row._id == "63a059c42e2226e631e3d6f2" ? (
          <div className="cellAction">
            {/* <Link to="/users/test" style={{ textDecoration: "none" }}> */}
            <div className="disableButton">Edit</div>
            {/* </Link> */}
            <div className="disableButton">Delete</div>
          </div>
        ) : (
          <div className="cellAction">
            {/* <Link to="/users/test" style={{ textDecoration: "none" }}> */}
            <div
              className="viewButton"
              onClick={() => (setTopicId(params.row._id), setModalShow(true))}
            >
              Edit
            </div>
            {/* </Link> */}
            <div
              className="deleteButton"
              onClick={() => deleteTopic(params.row._id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  const onSearch = (query) => {
    let filter = searchvalue.filter((item) =>
      Object.values(item).join("").toLowerCase().includes(query.toLowerCase())
    );
    setAlltopic(filter);
  };

  return (
    <>
      <div className="">
        <div className="search_area">
          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            justifyContent={"flex-end"}
            sx={{ mb: 2 }}
          >
            <input
              type="text"
              class="form-control "
              id="Inputtext"
              placeholder="search area"
              onChange={(e) => {
                onSearch(e.target.value);
              }}
              style={{ width: "33%" }}
            />
            <Button>Search</Button>
            <Button onClick={() => setModalShow(true)}>Add Topic</Button>
          </Stack>
        </div>
        <Box
          sx={{
            height: 475,
            width: "100%",
            "@media (min-width:1200px)": { height: 740 },
          }}
          className="loadercenter"
        >
          <DataGrid
            rows={alltopic}
            columns={columns}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            loading={isLoading}
          />
        </Box>
      </div>
      <Addnewtopic
        submit={submit}
        topicid={topicid}
        show={modalShow}
        onHide={() => (setTopicId(""), setModalShow(false))}
      />
    </>
  );
};

export default TopicsManagement;
