import { React, useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import "../../Assets/Css/admin.css";
import { Button } from "react-bootstrap";
import Addnewtopic from "./Modal/Addnewtopic";
import { getAllGroups } from "../../Api/auth";
import moment from "moment";
import { Stack, Box } from "@mui/material";

const GroupManagement = () => {
  const [modalShow, setModalShow] = useState(false);
  const [allGroup, setAllGroup] = useState([]);
  const [topicid, setTopicId] = useState("");
  const [searchvalue, setSearchValue] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const submit = async () => {
    const response = await getAllGroups();

    if (response?.remote == "success") {
      setSearchValue(
        response.data.groups.map((group, idx) => {
          return {
            _id: group._id,
            id: idx + 1,
            date: moment(group.createdAt).format("DD-MM-YYYY"),
            group: group?.groupName,
            groupsize: 4 - group.availableSize,
          };
        })
      );
      setAllGroup(
        response.data.groups.map((group, idx) => {
          return {
            _id: group._id,
            id: idx + 1,
            date: moment(group.createdAt).format("DD-MM-YYYY"),
            group: group?.groupName,
            groupsize: 4 - group.availableSize,
          };
        })
      );
      setIsLoading(false);
    } else {
      // setErrors(response.errors.errors);
      console.log(response.errors);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    submit();
  }, []);

  const columns = [
    { field: "id", headerName: " Id", width: 70 },
    { field: "date", headerName: "Date", width: 130 },
    { field: "group", headerName: "Group Name", width: 230 },
    { field: "groupsize", headerName: "Group Size", width: 130 },
  ];
  //   const onSearch = (query) => {
  //     let filter = searchvalue.filter((item) =>
  //       Object.values(item).join("").toLowerCase().includes(query.toLowerCase())
  //     );
  //     setAllGroup(filter);
  //   };

  return (
    <>
      <div className="">
        <div className="search_area">
          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            justifyContent={"flex-end"}
            sx={{ mb: 2 }}
          >
            <input
              type="text"
              class="form-control "
              id="Inputtext"
              placeholder="search area"
              style={{ width: "33%" }}
            />
            <Button>Search</Button>
          </Stack>
        </div>
        <Box
          sx={{
            height: 475,
            width: "100%",
            "@media (min-width:1200px)": { height: 740 },
          }}
          className="loadercenter"
        >
          <DataGrid
            rows={allGroup}
            columns={columns}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            loading={isLoading}
          />
        </Box>
      </div>
      <Addnewtopic
        submit={submit}
        topicid={topicid}
        show={modalShow}
        onHide={() => (setTopicId(""), setModalShow(false))}
      />
    </>
  );
};

export default GroupManagement;
