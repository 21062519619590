import { React, useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import "../../Assets/Css/admin.css";
import { Button } from "react-bootstrap";
import Addsubtopic from "./Modal/Addsubtopic";
import { getAllsubtopic, deletesubtopic } from "../../Api/auth";
import moment from "moment";
import Swal from "sweetalert2";
import { Stack, Box } from "@mui/material";
const SubtopicManagment = () => {
  const [modalShow, setModalShow] = useState(false);
  const [allsubtopic, setAllsubtopic] = useState([]);
  const [subtopicid, setSubtopicId] = useState("");
  const [searchvalue, setSearchValue] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const submit = async () => {
    const response = await getAllsubtopic();
    if (response.remote == "success") {
      setSearchValue(
        response.data.subTopic.map((subtopic, idx) => {
          return {
            _id: subtopic?._id,
            id: idx + 1,
            date: subtopic?.createdAt,
            topic: subtopic.topicId?.name,
            subtopic: subtopic?.subTopicName,
            status: subtopic?.status,
          };
        })
      );
      setAllsubtopic(
        response.data.subTopic.map((subtopic, idx) => {
          return {
            _id: subtopic?._id,
            id: idx + 1,
            date: moment(subtopic?.createdAt).format("DD-MM-YYYY"),
            topic: subtopic.topicId?.name,
            subtopic: subtopic?.subTopicName,
            status: subtopic?.status,
          };
        })
      );
      setIsLoading(false);
    } else {
      // setErrors(response.errors.errors);
      console.log(response.errors);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    submit();
  }, []);

  const deleteSubTopic = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deletesubtopic(id);
        if (response.remote == "success") {
          submit();
        } else {
          // setErrors(response.errors.errors);
          console.log(response.errors);
          // setIsLoading(false);
        }
        Swal.fire("Deleted!", "Your record  has been deleted.", "success");
      }
    });
  };

  const columns = [
    { field: "id", headerName: "Id", width: 70 },
    { field: "date", headerName: "Date", width: 110 },
    { field: "topic", headerName: " Topic Name", width: 150 },
    { field: "subtopic", headerName: " Sub Topic Name", width: 200 },

    {
      field: "status",
      headerName: "Status",
      width: 160,
      renderCell: (params) => {
        return (
          <div
            className={`cellWithStatus ${
              params.row.status ? "active" : "inactive"
            }`}
          >
            {params.row.status ? "Active" : "Inactive"}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 300,
      renderCell: (params) => {
        return params.row.topic == "Live Sports" ? (
          <div className="cellAction">
            <div className="disableButton">Edit</div>

            <div className="disableButton">Delete</div>
          </div>
        ) : (
          <div className="cellAction">
            {/* <Link to="/users/test" style={{ textDecoration: "none" }}> */}
            <div
              className="viewButton"
              onClick={() => (
                setSubtopicId(params.row._id), setModalShow(true)
              )}
            >
              Edit
            </div>
            {/* </Link> */}
            <div
              className="deleteButton"
              onClick={() => deleteSubTopic(params.row._id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  const onSearch = (query) => {
    let filter = searchvalue.filter((item) =>
      Object.values(item).join("").toLowerCase().includes(query.toLowerCase())
    );
    setAllsubtopic(filter);
  };
  return (
    <>
      <div className="">
        <div className="search_area">
          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            justifyContent={"flex-end"}
            sx={{ mb: 2 }}
          >
            <input
              type="text"
              class="form-control "
              id="Inputtext"
              placeholder="search area"
              onChange={(e) => {
                onSearch(e.target.value);
              }}
              style={{ width: "33%" }}
            />
            <Button>Search</Button>
            <Button onClick={() => setModalShow(true)}>Add Sub Topic</Button>
          </Stack>
        </div>
        <Box
          sx={{
            height: 475,
            width: "100%",
            "@media (min-width:1200px)": { height: 740 },
          }}
          className="loadercenter"
        >
          <DataGrid
            rows={allsubtopic}
            columns={columns}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            loading={isLoading}
          />
        </Box>
      </div>
      <Addsubtopic
        submit={submit}
        subtopicid={subtopicid}
        show={modalShow}
        onHide={() => (setSubtopicId(""), setModalShow(false))}
      />
    </>
  );
};

export default SubtopicManagment;
