import React from "react";
import DashboardCard from "./DashboardCard";
import "../../Assets/Css/Dashboard.css";

const Dashboard = () => {
  return (
    <div className="home ">
      <DashboardCard />
    </div>
  );
};

export default Dashboard;
