import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import "../../Assets/Css/admin.css";
import { Box, Stack } from "@mui/material";
import { Button } from "react-bootstrap";
import Addpage from "./Modal/Addpage";
import { getAllContact, deleteContact } from "../../Api/auth";
import moment from "moment";
import Swal from "sweetalert2";
import AddNewModal from "./Modal/AddNewModal";

const ContentManagement = () => {
  const [modalShow, setModalShow] = useState(false);
  const [searchvalue, setSearchValue] = useState([]);
  const [allcontact, setAllcontact] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userid, setUserId] = useState("");

  const selectUser = (deviceId) => {
    setUserId(deviceId);

    setTimeout(() => {
      setModalShow(true);
    }, 1000);
  };

  const submit = async () => {
    const response = await getAllContact();

    if (response.remote === "success") {
      setSearchValue(
        response.data.contacts.map((contact, idx) => {
          return {
            _id: contact?._id,
            id: idx + 1,
            date: moment(contact?.createdAt).format("DD-MM-YYYY"),
            title: contact?.title,
            content: contact?.description,
            sender: contact?.userId
              ? contact?.userId?.firstInitial + contact?.userId?.lastInitial
              : "User deleted",
            country: contact?.userId?.countryName,
            deviceId: contact.userId?.deviceId,
          };
        })
      );
      setAllcontact(
        response.data.contacts.map((contact, idx) => {
          return {
            _id: contact?._id,
            id: idx + 1,
            date: moment(contact?.createdAt).format("DD-MM-YYYY"),
            title: contact?.title,
            content: contact?.description,
            sender: contact?.userId
              ? contact?.userId?.firstInitial + contact?.userId?.lastInitial
              : "User deleted",
            country: contact?.userId?.countryName,
            deviceId: contact.userId?.deviceId,
          };
        })
      );
      setIsLoading(false);
    } else {
      // setErrors(response.errors.errors);
      console.log(response.errors);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    submit();
  }, []);
  const deleteContacts = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteContact(id);
        if (response.remote == "success") {
          const newData = allcontact.filter((item) => {
            return item._id !== id;
          });

          setAllcontact(newData);
        } else {
          // setErrors(response.errors.errors);
          console.log(response.errors);
          // setIsLoading(false);
        }
        Swal.fire("Deleted!", "Your record  has been deleted.", "success");
      }
    });
  };

  const columns = [
    { field: "id", headerName: " Id", width: 70 },
    { field: "date", headerName: "Date", width: 110 },
    { field: "title", headerName: "Title", width: 130 },
    { field: "content", headerName: "Discription", width: 250 },
    {
      field: "sender",
      headerName: "Sender Initial",
      width: 250,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              onClick={() => {
                selectUser(params.row.deviceId);
              }}
            >
              {params.row.sender}
            </div>
          </div>
        );
      },
    },
    { field: "country", headerName: "Country", width: 250 },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   width: 160,
    //   renderCell: (params) => {
    //     return (
    //       <div className={`cellWithStatus ${params.row.status}`}>
    //         {params.row.status}
    //       </div>
    //     );
    //   },
    // },

    {
      field: "action",
      headerName: "ACTION ",
      width: 300,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              className="deleteButton"
              onClick={() => deleteContacts(params.row._id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  const onSearch = (query) => {
    let filter = searchvalue.filter((item) =>
      Object.values(item).join("").toLowerCase().includes(query.toLowerCase())
    );
    setAllcontact(filter);
  };

  return (
    <>
      <div className="">
        <div className="search_area">
          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            justifyContent={"flex-end"}
            sx={{ mb: 2 }}
          >
            <input
              type="text"
              class="form-control "
              id="Inputtext"
              placeholder="search area"
              onChange={(e) => {
                onSearch(e.target.value);
              }}
              style={{ width: "33%" }}
            />
            <Button>Search</Button>
            {/* <Button onClick={() => setModalShow(true)}>Add Contact</Button> */}
          </Stack>
        </div>
        <Box
          sx={{
            height: 475,
            width: "100%",
            "@media (min-width:1200px)": { height: 740 },
          }}
          className="loadercenter"
        >
          <DataGrid
            rows={allcontact}
            columns={columns}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            loading={isLoading}
          />
        </Box>
      </div>
      <Addpage
        show={modalShow}
        onHide={() => setModalShow(false)}
        submit={submit}
      />

      <AddNewModal
        show={modalShow}
        userid={userid}
        onHide={() => setModalShow(false)}
        // updateUserDetail={() => GetAllUser()}
      />
    </>
  );
};

export default ContentManagement;
