import React from 'react'
import "../../Assets/Css/admin.css"
import { Form, Table } from "react-bootstrap";
import avatar1 from "../../Assets/images/avatar-1.jpg";

const ReportedUserTable = () => {
  return (
    <div className="px-3">
      <Table bordered responsive striped hover>
        <thead>
          <tr>
            <th>Avatar</th>
            <th>User Name</th>
            <th>Reported By</th>
            <th>Reported Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <img src={avatar1} className="admin_avatar_img" />
            </td>
            <td className="pt-3">Akash</td>
            <td className="pt-3">gggg</td>
            <td className="pt-3">8/11/2022</td>
            <td className="pt-3">
              <Form>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=""
                  />
              </Form>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

export default ReportedUserTable